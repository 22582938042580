function conditionsToString(conditions, supportedFunctions, groups) {
	// Check if conditions is empty, undefined, or specifically an empty string
	if (!conditions || conditions.length === 0 || conditions === "") {
		return "";
	}

	const conditionMap = {};

	// Map each condition by its ID and log initial conditions
	conditions.forEach((cond) => {
		conditionMap[cond.condition_id] = `(${cond.key} ${cond.num_op} ${cond.value})`;
	});

	const constructFunctionMap = {};
	supportedFunctions.forEach((func) => {
		constructFunctionMap[func.function_id] = {
			type: func.type,
			value: func.value,
			targetId: func.id,
		};
	});

	const buildExpression = (funcId) => {
		const func = constructFunctionMap[funcId];
		if (!func) return null;

		let targetExpr = conditionMap[func.targetId] || buildExpression(func.targetId);
		if (!targetExpr) {
			return null;
		}

		let parts = targetExpr.match(/^\((.*?)( in range |\s[>=<]+ )(\[[^\]]+\]|[^)]+)\)$/);
		if (parts) {
			let [_, expr, operator, value] = parts;
			let newExpr = `${func.type}(${expr.trim()}, ${func.value})`;
			return `(${newExpr} ${operator.trim()} ${value})`;
		} else {
			return targetExpr; // Fallback to return the original target if regex fails
		}
	};

	const FunctionMap = {};
	Object.keys(constructFunctionMap).forEach((funcId) => {
		FunctionMap[funcId] = buildExpression(funcId);
	});

	// Handle case when there's only one condition and no groups
	if (conditions.length === 1 && groups.length === 0) {
		let conditionStr = conditionMap[conditions[0].condition_id];
		conditionStr = conditionStr.replace(/^\(|\)$/g, "");
		return conditionStr;
	}

	// Log and create group combinations based on logical operations
	const groupMap = {};

	if (groups.length > 0) {
		groups.forEach((group, index) => {
			const first = conditionMap[group.id_1] || groupMap[group.id_1] || FunctionMap[group.id_1];
			const second = conditionMap[group.id_2] || groupMap[group.id_2] || FunctionMap[group.id_2];
			const combined = `(${first} ${group.logical_operation}\n${second})`;
			groupMap[group.group_id] = combined;
		});
		// Get the final group, which is the last one created, and log the final expression
		const finalExpression = groupMap[groups[groups.length - 1].group_id];

		// Trim the first and last characters from the final expression than are extra parentheses
		const trimmedFinalExpression = finalExpression.slice(1, -1);

		return trimmedFinalExpression;
	}
	return "";
}

export { conditionsToString };