// OptionsField.js
import React from "react";
import { Chip, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	optionsField: {
		display: "flex",
		flexWrap: "wrap",
		gap: "10px",
		marginBottom: "10px",
		marginTop: "10px",
	},
	chip: {
		backgroundColor: "#171717",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#333",
		},
		cursor: "pointer",
	},
	labelWithLine: {
		color: "#fff",
		position: "relative",
		display: "block",
		marginBottom: "10px",
		paddingBottom: "5px",
		borderBottom: "2px solid #fff",
		paddingRight: "10px",
		paddingLeft: "10px",
		backgroundColor: "transparent",
	},
}));

const OptionsField = ({ id, label, options = [] }) => {
	const classes = useStyles();

	const handleChipClick = (option) => {
		navigator.clipboard.writeText(option.value);
	};

	if (!options.length) {
		return null;
	}

	return (
		<>
			{label && <label className={classes.labelWithLine}>{label}:</label>}
			<Box className={classes.optionsField}>
				{options.map((option, index) => (
					<Chip
						key={`${index}_${id}`}
						label={option.label}
						onClick={() => handleChipClick(option)}
						className={classes.chip}
					/>
				))}
			</Box>
		</>
	);
};

export default OptionsField;
