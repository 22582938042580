// eslint-disable-next-line no-unused-vars
import * as React from 'react';
import { useNavigate } from "react-router-dom";

import {
	Box,
	Grid,
	Typography,
	Chip,
	List, ListItem, ListItemText, ListItemSecondaryAction,
	IconButton,
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';

// import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorIcon from '@mui/icons-material/Error';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { makeStyles } from '@mui/styles';

import MDEditor from "@uiw/react-md-editor";

import { memo, useCallback, useEffect, useState } from "react";

// import { PrimaryBorderButton } from "../components/Buttons.js";
import Popup from "../components/Popup.js";
// import Search from "../components/Search.js";
// import DatePicker from "../components/DatePicker.js";
import Spinner from "../components/Spinner.js";
import Form from "../components/Form.js";
import { useSnackbar, jwt } from "../utils/index.js";
// import { use } from 'i18next';
import AreYouSurePopup from "../components/Popups/AreYouSurePopup.js";

import {
	getUsersModels,
	newUserModel,
	removeModel,
	cloneModel,
} from '../api/index.js';

import { getDslConstants } from '../dsls/index.js';

const useStyles = makeStyles({
	buttonCursor: {
		cursor: "pointer",
	},
	select: {
		color: "#F1A542", // Set the desired color for the select
		"&:before": {
			borderColor: "#F1A542", // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: "#F1A542", // Set the desired color for the select's border when focused
		},
	},
});

const DslScreen = (params) => {
	const { error, success } = useSnackbar();
	// eslint-disable-next-line no-unused-vars
	const [currentDsl, setCurrentDsl] = useState(params.dsl);
	const [isLoading, setIsLoading] = useState(false);
	const [documentation, setDocumentation] = useState("");
	const [supportsValidation, setSupportsValidation] = useState(true);
	const [addModelPopupOpen, setAddModelPopupOpen] = useState(false);
	const [cloneModelPopupOpen, setCloneModelPopupOpen] = useState(false);
	const [models, setModels] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [user, setUser] = useState(jwt.decode());
	const [modelToDelete, setModelToDelete] = useState(null);
	const [modelToClone, setModelToClone] = useState(null);
	const [deleteModelPopupOpen, setDeleteModelPopupOpen] = useState(false);

	const classes = useStyles();
	const navigate = useNavigate();

	// eslint-disable-next-line no-unused-vars
	const [screenSize, setScreenSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const newModelFormContent = [
		{
			customType: "wideInput",
			id: "title",
			type: "input",
			multiline: false,
			width: 400,
			placeholder: "Title",
		},
		{
			customType: "wideInput",
			id: "description",
			type: "input",
			multiline: true,
			minRows: 3,
			width: 400,
			placeholder: "Description",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Add model",
		},
	];

	const cloneModelFormContent = [
		{
			customType: "wideInput",
			id: "title",
			type: "input",
			multiline: false,
			width: 400,
			placeholder: "New title",
			value: modelToClone?.title,
		},
		{
			customType: "wideInput",
			id: "description",
			type: "input",
			multiline: true,
			minRows: 3,
			width: 400,
			placeholder: "New description",
			value: modelToClone?.description,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Clone model",
		},
	];

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			// const shortDescription
			const description = (await getDslConstants(currentDsl));
			setDocumentation(description.shortDescription);
			setSupportsValidation(description.hasValidation);

			// Fetch the user's models
			const { success: scs, models: mds } = await getUsersModels(user.id, currentDsl, false);
			console.log("Models", mds);
			if (scs) {
				setModels(mds);
			} else {
				error();
			}

			setIsLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[error, user.id],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	const addModelSubmitHandler = async (values) => {
		setIsLoading(true);
		console.log(values);
		try {
			const { success: successCode, message } = await newUserModel(
				user.id,
				currentDsl,
				values.title,
				values.description,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddModelPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const cloneModelSubmitHandler = async (values) => {
		setIsLoading(true);
		console.log(values);
		try {
			const { success: successCode, message } = await cloneModel(
				modelToClone._id,
				values.title,
				values.description,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setModelToClone(null);
			setCloneModelPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const deleteModel = async () => {
		setIsLoading(true);
		const { success: successCode, message } = await removeModel(modelToDelete._id);

		if (successCode) {
			success(message);
		} else {
			error(message);
		}

		await fetchData();

		setIsLoading(false);
		setDeleteModelPopupOpen(false);
		setModelToDelete(null);
	};

	const declineDeleteModel = () => {
		setDeleteModelPopupOpen(false);
		setModelToDelete({});
	};

	return (
		<>
			<Spinner open={isLoading} />
			<AreYouSurePopup
				open={deleteModelPopupOpen}
				title="Delete model?"
				content={`Are you sure you want to delete the model "${modelToDelete?.title}"?`}
				onDecline={declineDeleteModel}
				onAccept={deleteModel}
			/>
			<Popup
				width="800px"
				open={addModelPopupOpen}
				title="Create new model"
				onClose={() => {
					setAddModelPopupOpen(false);
				}}
			>
				<Form
					content={newModelFormContent}
					onSubmit={addModelSubmitHandler}
				/>
			</Popup>
			<Popup
				width="800px"
				open={cloneModelPopupOpen}
				title="Clone the model into a new one"
				onClose={() => {
					setCloneModelPopupOpen(false);
				}}
			>
				<Form
					content={cloneModelFormContent}
					onSubmit={cloneModelSubmitHandler}
				/>
			</Popup>
			<Grid
				container
				item
				mt={2}
				mb={2}
				width="100%"
				display="flex"
				flexDirection="row"
				justifyContent="space-evenly"
			>
				<Grid
					container
					item
					width="100%"
					xs={7.9}
					display="flex"
					flexDirection="column"
					sx={{
						border: 1,
						borderColor: "#262835",
						borderTop: 0,
						borderRadius: "20px",
						boxShadow: "0px 0px 20px #262835",
					}}
				>
					<Grid
						item
						display="flex"
						flexDirection="column"
						alignItems="center"
					>
						<Grid
							item
							sx={{
								borderRadius: 10,
								alignItems: "center",
								flex: 1,
								width: "100%",
							}}
						>
							<MDEditor
								hideToolbar
								value={documentation}
								height={screenSize.height - 200}
								maxHeight={1200}
								fullscreen={false}
								tabSize={2}
								preview="preview"
								style={{
									zIndex: 100,
									borderRadius: 10,
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					item
					width="100%"
					xs={3.9}
					display="flex"
					flexDirection="column"
				>
					<Grid
						container
						item
						width="100%"
						pl={2}
						display="flex"
						minHeight="50px"
						maxHeight="50px"
						alignItems="center"
						sx={{
							borderBottom: 0.1,
							borderColor: "secondary.main",
						}}
					>
						<Grid
							item
							xs={9}
						>
							<Typography variant="h6" color="#fff">
								{"Your models"}
							</Typography>
						</Grid>
						<Grid
							item
							xs={3}
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="flex-end"
							pr={1}
						>
							<Chip
								key={-1}
								label="+"
								color='success'
								sx={{
									mr: 0.5,
								}}
								onClick={() => setAddModelPopupOpen(true)}
							/>
						</Grid>
					</Grid>
					<List
						dense
					>
						{models.map((e, ind) => (
							<ListItem
								key={ind}
								sx={{
									py: 0,
								}}
							>
								<ListItemText
									sx={{
										color: "#ffffff",
										padding: 0.5,
										'& .MuiListItemText-primary': {
											fontWeight: 'bold', // Make primary text bold
										},
									}}
									primary={(
										<Box display="flex" alignContent="center" alignItems="center">
											<Typography
												fontSize={12}
												color="#aaa"
												sx={{ opacity: 0.7, marginRight: 1 }}
											>
												{`${e.updatedAt.split("T")[0]}`}
											</Typography>
											<Typography
												className={classes.buttonCursor}
												style={{
													color: 'white',
													fontWeight: 'bold',
													textDecoration: 'none',
												}}
												onClick={() => { navigate(`/dsls/${currentDsl}/${e._id}`); }}
											>
												{e.title}
											</Typography>
										</Box>
									)}
								/>
								<ListItemSecondaryAction>
									<IconButton edge="end" aria-label="view">
										<Tooltip title={e.validated || !supportsValidation ? "Valid" : "Invalid"}>
											{e.validated || !supportsValidation ? <TaskAltIcon style={{ color: "green" }} /> : <ErrorIcon style={{ color: "orange" }} />}
										</Tooltip>
									</IconButton>
									<IconButton
										edge="end"
										aria-label="view"
										className={classes.buttonCursor}
										onClick={() => {
											setModelToClone(e);
											setCloneModelPopupOpen(true);
										}}
									>
										<Tooltip title="Clone model">
											<ContentCopyIcon style={{ color: "#aaa" }} />
										</Tooltip>
									</IconButton>
									<IconButton
										edge="end"
										aria-label="delete"
										className={classes.buttonCursor}
										onClick={() => {
											setModelToDelete(e);
											setDeleteModelPopupOpen(true);
										}}
									>
										<Tooltip title="Delete model">
											<DeleteIcon style={{ color: "red" }} />
										</Tooltip>
									</IconButton>
								</ListItemSecondaryAction>

							</ListItem>
						))}
					</List>
				</Grid>
			</Grid>
		</>
	);
};

export default memo(DslScreen);
