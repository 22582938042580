// WatchField.js
import React from "react";
import { TextField } from "@mui/material";
import { conditionsToString } from "./create-graph/utils/conditionsToString";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	watchField: {
		display: "flex",
		alignItems: "center",
		gap: "10px",
		marginBottom: "10px",
		marginTop: "10px",
	},
	watchField__inputs: {
		backgroundColor: "#171717",
		opacity: 0.8,
		color: "#fff",
		WebkitTextFillColor: "#fff!important",
		borderRadius: "4px",
		marginBottom: "10px",
		"&:hover": {
			backgroundColor: "#171717",
			opacity: 0.9,
		},
	},
	labelWithLine: {
		color: "#fff",
		position: "relative",
		display: "block",
		marginBottom: "10px",
		paddingBottom: "5px",
		borderBottom: "2px solid #fff",
		paddingRight: "10px",
		paddingLeft: "10px",
		backgroundColor: "transparent",
	},
}));

const getTargetFieldValues = (targetFields, formikProps) => {
	const fieldValues = {};
	targetFields.forEach((field) => {
		fieldValues[field] = formikProps.values[field];
	});
	return fieldValues;
};

const WatchField = ({
	label,
	id,
	type,
	multiline,
	minRows,
	maxRows,
	placeholder,
	variant,
	color,
	targetFields,
	formikProps,
	error,
	disabled,
	onChange,
}) => {
	const classes = useStyles();

	const jsonValues = getTargetFieldValues(targetFields, formikProps);
	const { condition, functions, groups } = jsonValues;
	const finalString = conditionsToString(condition, functions, groups);
	return (
		<>
			{label && <label className={classes.labelWithLine}>{label}:</label>}
			<div className={classes.watchField}>
				<TextField
					label={label}
					id={id}
					type={type}
					multiline={multiline}
					minRows={minRows}
					maxRows={maxRows}
					className={classes.watchField__inputs}
					variant={variant}
					color={color}
					value={finalString}
					error={error}
					onChange={onChange}
					fullWidth
                    sx={{
						style: { color: "#fff!important"}, 
					}}
				/>
			</div>
		</>
	);
};

export default WatchField;
