import React from "react";
import { getBezierPath, BaseEdge, EdgeLabelRenderer, useReactFlow } from "reactflow";

export default function CustomEdge({
	id,
	source,
	target,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	style = {},
	markerEnd,
	dslJson,
}) {
	const xEqual = sourceX === targetX;
	const yEqual = sourceY === targetY;
	const { setEdges } = useReactFlow();

	let [edgePath, labelX, labelY] = getBezierPath({
		sourceX: xEqual ? sourceX + 0.0001 : sourceX,
		sourceY: yEqual ? sourceY + 0.0001 : sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition,
	});

	let labelStyle = {
		position: "absolute",
		transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
		fontSize: "12px",
		pointerEvents: "all",
		opacity: style.labelOpacity,
	};

	const onEdgeClick = () => {
		setEdges((edges) => edges.filter((edge) => edge.id !== id));
	};

	if (source === target) {
		const radiusX = (sourceX - targetX) * 0.6;
		const radiusY = 50;
		edgePath = `M ${sourceX - 5} ${sourceY} A ${radiusX} ${radiusY} 0 1 0 ${targetX + 2} ${targetY}`;

		labelStyle.transform = `translate(-50%, -450%) translate(${labelX}px,${labelY}px)`;
	}
	
	const getGradientId = (id) => {
		if (dslJson && dslJson.nodeTypes) {
			for (const type in dslJson.nodeTypes) {
				const nodeType = dslJson.nodeTypes[type];
				if (nodeType.colorConfig) {
					for (const key in nodeType.colorConfig) {
						if (id.includes(key)) {
							return `url(#edge-gradient-${type}-${key})`;
						}
					}
				}
			}
		}
		return style.stroke;
	};

	const edgeStyle = {
		...style,
		stroke: getGradientId(id),
		strokeWidth: 2,
	};

	return (
		<>
			<BaseEdge
				path={edgePath}
				id={id}
				style={edgeStyle}
				className="react-flow__edge-path"
				d={edgePath}
				markerEnd={markerEnd}
			/>
			<EdgeLabelRenderer>
				<div style={labelStyle} className="nodrag nopan close-button">
					<button className="edgebutton" onClick={onEdgeClick}>
						×
					</button>
				</div>
			</EdgeLabelRenderer>
		</>
	);
}
