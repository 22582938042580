import React from "react";
import { useNodeHandlers } from "../custom_nodes/useNodeHandlers";
import { getDependenciesArray } from "../initial-elements/initial-elements";
import BlockIcon from '@mui/icons-material/Block'; 

const Sidebar = ({ openPopup, hyperData, onScreenChange, dslJson }) => {
    const { extractMultipleTitlesByType, getNodes } = useNodeHandlers(); // Get the getNodes function to retrieve current nodes in the instance
    const nodes = getNodes(); // Get the current nodes from the React Flow instance

    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData("application/reactflow", nodeType);
        event.dataTransfer.effectAllowed = "move";
    };

    const draggableNodes = [];
    const buttonNodes = [];

    Object.keys(dslJson.nodeTypes).forEach((nodeType) => {
        const nodeData = dslJson.nodeTypes[nodeType];
        const currentNodeCount = nodes.filter((node) => node.data.type === nodeType).length; // Count the instances of the current node type
        const maxInstances = nodeData.maxInstances || -1; // Get the maxInstances from the DSL JSON

        // Check if the node type is draggable and if the current node count is less than the maxInstances
        if (nodeData.draggable) {
            const isDisabled = maxInstances !== -1 && currentNodeCount >= maxInstances; // Disable if maxInstances is reached
	
            draggableNodes.push(
				<div className="dndnode-wrapper" key={nodeType} style={{ position: 'relative' }}>
				<div
					className={`dndnode ${isDisabled ? "disabled" : ""}`}
					onDragStart={isDisabled ? null : (event) => onDragStart(event, nodeType)}
					draggable={!isDisabled}
					style={{ cursor: isDisabled ? "not-allowed" : "move" }}
				>
					{nodeData.title}
				</div>
					{isDisabled && (
						<BlockIcon
							style={{
								position: 'absolute',
								top: 1,
								right: 1,
								color: 'white',
								cursor: "not-allowed",
								fontSize: '14px',
							}}
						/>
					)}
				</div>,
            );
        } else {
            const hyperDataItem = hyperData[nodeType];
            if (hyperDataItem?.type) {
                hyperDataItem.dependenciesOptions = extractMultipleTitlesByType(hyperDataItem.dependencies || []);
                buttonNodes.push(
                    <div className="dndnode-wrapper" key={nodeType}>
                        <div className={`dndnode static`} onClick={() => openPopup(hyperDataItem)}>
                            {nodeData.title}
                        </div>
                    </div>
                );
            } else {
                const optionsDependecies = extractMultipleTitlesByType(getDependenciesArray(nodeData.dependencies) || []);
                let defaultData = {};
                nodeData.parameters.forEach(parameter => {
                    const defaultValue = parameter.default !== undefined ? parameter.default : "";
                    defaultData[parameter.name] = defaultValue;
                });
                const popupData = {
                    type: nodeType,
                    title: nodeData.title,
                    uniqueId: nodeData.title,
                    dependenciesOptions: optionsDependecies,
                    ...defaultData,
                };
                buttonNodes.push(
                    <div className="dndnode-wrapper" key={nodeType}>
                        <div className="dndnode static" onClick={() => openPopup(popupData, "create")}>
                            {nodeData.title}
                            {` `}
                            <i className="icon-plus">+</i>
                        </div>
                    </div>
                );
            }
        }
    });

    return (
        <aside>
            {!onScreenChange && (
                <div className="description">
                    <h3>
                        <strong>The default controls are:</strong>
                    </h3>
                    <ul>
                        <li>
                            <h4>pan: drag mouse</h4>
                        </li>
                        <li>
                            <h4>zoom: scroll</h4>
                        </li>
                        <li>
                            <h4>create selection: Shift + drag</h4>
                        </li>
                    </ul>
                    <br />
                    <h3>
                        <strong>You can drag the below nodes to the panel on the left.</strong>
                    </h3>
                </div>
            )}
            <div className="dndnode-container">
                <h3 style={{ width: "100%" }}>
                    <strong>Draggable Entities:</strong>
                </h3>
                {draggableNodes}
                <br />
                
                {buttonNodes.length !== 0 && (
                    <>
                        <h3 style={{ width: "100%" }}>
                            <strong>Buttons Entities:</strong>
                        </h3>
                        {buttonNodes}
                    </>
                )}
            </div>
        </aside>
    );
};

export default Sidebar;
