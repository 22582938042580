import { memo, useCallback, useEffect, useState } from "react";

import { Grid, Typography, Avatar } from "@mui/material";
import Chip from '@mui/material/Chip';

import DeleteIcon from '@mui/icons-material/Delete';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import Popup from "../components/Popup.js";
import Spinner from "../components/Spinner.js";
import Form from "../components/Form.js";
import { useSnackbar, jwt } from "../utils/index.js";

import { makeStyles } from '@mui/styles';

import {
	addCollaborator,
	getCollaborators,
	removeCollaborator,
} from "../api/index.js";

const useStyles = makeStyles({
	buttonCursor: {
		cursor: "pointer",
	},
	select: {
		color: "#F1A542", // Set the desired color for the select
		"&:before": {
			borderColor: "#F1A542", // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: "#F1A542", // Set the desired color for the select's border when focused
		},
	},
});

const Collaboration = () => {
	const { success, error } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [collaborators, setCollaborators] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [groups, setGroups] = useState([]);
	const [addCollaboratorPopupOpen, setAddCollaboratorPopupOpen] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [user, setUser] = useState(jwt.decode());

	const classes = useStyles();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			const colls = await getCollaborators(user.id);
			console.log(colls.collaborators);
			setCollaborators(colls.collaborators);
			setIsLoading(false);
		},
		[user.id],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	const addCollaboratorSubmitHandler = async (values) => {
		setIsLoading(true);
		console.log(values);
		try {
			const { success: successCode, message } = await addCollaborator(
				user.id,
				values.email,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddCollaboratorPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const removeCollaboratorSubmitHandler = async (collId) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await removeCollaborator(
				user.id,
				collId,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const newCollaboratorFormContent = [
		{
			customType: "wideInput",
			id: "email",
			type: "input",
			multiline: false,
			width: 600,
			placeholder: "email",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Add collaborator",
		},
	];

	return (
		<>
			<Spinner open={isLoading} />
			<Popup
				width="800px"
				open={addCollaboratorPopupOpen}
				title="Create new collaborator"
				onClose={() => {
					setAddCollaboratorPopupOpen(false);
				}}
			>
				<Form
					content={newCollaboratorFormContent}
					onSubmit={addCollaboratorSubmitHandler}
				/>
			</Popup>
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			>

				<Grid
					container
					flexDirection="row"
					justifyContent="space-between"
				>
					<Grid
						container
						item
						width="100%"
						xs={5.8}
						justifyContent="center"
					>
						<Grid
							container
							item
							width="100%"
							mb={2}
							display="flex"
							minHeight="60px"
							borderRadius="20px"
							alignItems="center"
							justifyContent="space-between"
						>
							<Grid
								container
								item
								width="100%"
								pl={2}
								xs={12}
								display="flex"
								minHeight="50px"
								maxHeight="50px"
								alignItems="center"
								sx={{
									borderBottom: 0.1,
									borderColor: "secondary.main",
								}}
							>
								<Grid
									item
									xs={9}
								>
									<Typography variant="h6" color="#ffffff">
										{"Your collaborators"}
									</Typography>
								</Grid>
								<Grid
									item
									xs={3}
									display="flex"
									flexDirection="row"
									alignItems="center"
									justifyContent="flex-end"
									pr={1}
								>
									<Chip
										key={-1}
										label="+"
										color="success"
										sx={{
											mr: 0.5,
										}}
										onClick={() => setAddCollaboratorPopupOpen(true)}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Timeline
							sx={{
								[`& .${timelineItemClasses.root}:before`]: {
									flex: 0,
									padding: 0,
								},
							}}
							xs={7}
						>
							{
								collaborators?.length === 0 && (
									<Typography color="gray" pl={2} pb={1} pt={1} fontSize="0.8rem">
										{"No collaborators found."}
									</Typography>
								)
							}
							{collaborators?.map((e, ind) => (
								<TimelineItem key={ind}>
									<TimelineSeparator>
										<TimelineDot
											color="white"
											sx={{
												boxShadow: "2px 2px 14px #262835",
											}}
										>
											<AccountTreeIcon style={{ color: "green" }} />
										</TimelineDot>
										{ind !== collaborators.length - 1 && <TimelineConnector />}
									</TimelineSeparator>
									<TimelineContent>
										<Grid
											container
											display="flex"
											flexDirection="row"
										>
											<Grid
												item
												xs={10}
												display="flex"
												flexDirection="column"
											>
												<Typography fontSize="1.1rem" color="#ffffff">
													{e.fullname}
												</Typography>
												<Typography color="#ffffff" fontSize="0.8rem" mb={0.5}>
													{e.email}
												</Typography>
											</Grid>
											<Grid
												item
												xs={2}
												display="flex"
												flexDirection="row"
												alignItems="center"
												justifyContent="flex-end"
											>
												<Avatar
													sx={{
														bgcolor: "red",
														width: 38,
														height: 38,
													}}
													className={classes.buttonCursor}
													onClick={() => {
														removeCollaboratorSubmitHandler(e._id);
													}}
												>
													<DeleteIcon />
												</Avatar>
											</Grid>
										</Grid>
									</TimelineContent>
								</TimelineItem>
							))}
						</Timeline>
					</Grid>
					<Grid
						item
						width="100%"
						xs={5.8}
						ml={2}
						justifyContent="center"
					>
						<Grid
							container
							item
							width="100%"
							mb={2}
							display="flex"
							minHeight="60px"
							borderRadius="20px"
							alignItems="center"
							justifyContent="space-between"
						>
							<Grid
								container
								item
								width="100%"
								pl={2}
								xs={12}
								display="flex"
								minHeight="50px"
								maxHeight="50px"
								alignItems="center"
								sx={{
									borderBottom: 0.1,
									borderColor: "secondary.main",
								}}
							>
								<Grid
									item
									xs={9}
								>
									<Typography variant="h6" color="#ffffff">
										{"Your groups (not supported yet)"}
									</Typography>
								</Grid>
								<Grid
									item
									xs={3}
									display="flex"
									flexDirection="row"
									alignItems="center"
									justifyContent="flex-end"
									pr={1}
								>
									<Chip
										key={-1}
										label="+"
										color="success"
										sx={{
											mr: 0.5,
										}}
									// onClick={() => setAddProjectPopupOpen(true)}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Timeline
							sx={{
								[`& .${timelineItemClasses.root}:before`]: {
									flex: 0,
									padding: 0,
								},
							}}
							xs={7}
						>
							{
								groups.length === 0 && (
									<Typography color="gray" pl={2} pb={1} pt={1} fontSize="0.8rem">
										{"No groups found."}
									</Typography>
								)
							}
							{groups.map((e, ind) => (
								<TimelineItem key={ind}>
									<TimelineSeparator>
										<TimelineDot
											color="white"
											sx={{
												boxShadow: "2px 2px 14px #262835",
											}}
										>
											<AccountTreeIcon style={{ color: "green" }} />
										</TimelineDot>
										{ind !== groups.length - 1 && <TimelineConnector />}
									</TimelineSeparator>
									<TimelineContent>
										<Grid
											container
											display="flex"
											flexDirection="row"
										>
											<Grid
												item
												xs={10}
												display="flex"
												flexDirection="column"
											>
												<Typography fontSize="1.3rem" color="#ffffff">
													{e.title}
												</Typography>
												<Typography color="#ffffff" fontSize="0.6rem" mb={0.5}>
													{`Created at ${e.createdAt.replace("T", " ").replace("Z", "")}`}
												</Typography>
											</Grid>
											<Grid
												item
												xs={2}
												display="flex"
												flexDirection="row"
												alignItems="center"
												justifyContent="flex-end"
											>
												<Chip />
											</Grid>
										</Grid>
									</TimelineContent>
								</TimelineItem>
							))}
						</Timeline>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default memo(Collaboration);
