import { getConnectedEdges, useReactFlow } from "reactflow";
import { useSnackbar } from "../../../utils/index.js";
import { ContentCopy } from "@mui/icons-material";

export function useNodeHandlers() {
	const { close, error } = useSnackbar();
	const { setEdges, getNodes, getEdges, setNodes } = useReactFlow();
	const nodes = getNodes();
	const edges = getEdges();

	const extractTitlesByType = (type) => {
		return nodes.filter((node) => node.data.type === type).map((node) => node.data.title);
	};

	const extractMultipleTitlesByType = (types) => {
		return types.map((type) => ({
			type,
			titles: extractTitlesByType(type),
		}));
	};

	const extractOptions = (complexDependencies) => {
		const getValueFromPath = (obj, path) => {
			return path.split('.').reduce((acc, part) => {
				if (Array.isArray(acc)) {
					return acc.map(item => item[part]).filter(item => item !== undefined);
				}
				return acc && acc[part];
			}, obj);
		};
	
		const getType = (item) => {
			return item?.type || "string";
		};
	
		const extractFromNode = (node, format) => {
			const results = [];
			const paths = Array.from(format.matchAll(/\${(.*?)}/g)).map(match => match[1]);
	
			let complexPaths = paths.filter(path => path.includes('.'));
			let otherPaths = paths.filter(path => !path.includes('.'));
	
			complexPaths.forEach(path => {
				const [parent, child] = path.split('.');
				const attributes = getValueFromPath(node.data, parent);
	
				if (Array.isArray(attributes)) {
					attributes.forEach(attribute => {
						const formattedValue = format.replace(/\${(.*?)}/g, (_, path) => {
							if (path === `${parent}.${child}`) {
								return attribute[child];
							} else {
								return getValueFromPath(node.data, path);
							}
						});
	
						results.push({
							value: formattedValue,
							label: formattedValue,
							type: getType(attribute),
						});
					});
				}
			});
	
			// Maybe it will be needed in the future
			if(results.length == 0) {
				otherPaths.forEach(path => {
					const data = getValueFromPath(node.data, path);
					if (data !== undefined) {
						const formattedValue = format.replace(/\${(.*?)}/g, (_, path) => {
							return getValueFromPath(node.data, path) || '';
						});
						results.push({
							value: formattedValue,
							label: formattedValue,
							type: getType(node.data),
						});
					}
				});
			}
	
			return results;
		};
	
		const types = Object.keys(complexDependencies);
		const groupedOptions = {};
	
		nodes
			.filter((node) => types.includes(node.data.type)) // Filter out nodes by types
			.forEach((node) => {
				const nodeType = node.data.type;
				const dependency = complexDependencies[nodeType];
				const format = dependency.parameter ? `\${${dependency.parameter}}` : dependency.format;
	
				if (!groupedOptions[nodeType]) {
					groupedOptions[nodeType] = [];
				}
	
				const extractedOptions = extractFromNode(node, format);
				groupedOptions[nodeType].push(...extractedOptions);
			});
		return groupedOptions;
	};

	const onDeleteNode = (nodeId, data) => {
		setNodes((nds) => nds.filter((node) => node.id !== nodeId));
		data.onOpenPopup(data, "delete");
	};

	const onEdgeClick = (edgeId) => {
		setEdges((edges) => edges.filter((edge) => edge.id !== edgeId));
	};

	// Ensure the connection is allowed based on the targetDependencies
	// Function to flatten and extract nodeType values
	const extractNodeTypes = (target) => {
		if (Array.isArray(target)) {
			return target.map(t => t.nodeType);
		} else if (typeof target === 'object' && target !== null) {
			return [target.nodeType];
		}
		return [];
	};

	const handleConnect = (connection, id, data, connectionLimit, handleTarget, handleDependency) => {
		const targetHandleField = connection.targetHandle.split("-")[1];
		let isAllowedConnection;
		console.log("connection: ", connection);
		console.log("connectionLimit: ", connectionLimit);
		console.log("handleTarget: ", handleTarget);
		console.log("typeof handleTarget: ", typeof handleTarget);


		// Check if handleTarget is an object or array of objects with nodeType and parameter
		if (typeof handleTarget === 'object' && !Array.isArray(handleTarget) && (handleTarget.nodeType || Array.isArray(handleTarget))) {

			const handleTargetNodeTypes = extractNodeTypes(handleTarget);
			console.log("handleTargetNodeTypes: ", handleTargetNodeTypes);

			// Convert to lowercase for comparison
			const lowerCaseTargetNodeTypes = handleTargetNodeTypes.map(nodeType => nodeType.toLowerCase());
			const targetFieldLower = targetHandleField.toLowerCase();

			isAllowedConnection = lowerCaseTargetNodeTypes.includes(targetFieldLower);
			console.log("isAllowedConnection: ", isAllowedConnection);
		} else {
			// Retain original logic if handleTarget is not an object with nodeType and parameter
			isAllowedConnection = handleTarget.includes(targetHandleField.toLowerCase() || targetHandleField);
			console.log("isAllowedConnection: ", isAllowedConnection);
		}
		
		if (!isAllowedConnection) {
			console.log("handleTarget: ", handleTarget);
			console.log("targetHandleField: ", targetHandleField);
			console.log("edges.edges: ", edges);
			onEdgeClick(id);
			error(`${data.title} cannot connect to ${targetHandleField}!`);
			return false;
		}

		const getLimit = (limitObj, sourceType, isSource, targetType = null) => {

			if (isSource) {
				if (typeof limitObj[targetType] === "object") {
					console.log("1");
					return parseInt(limitObj[targetType][sourceType], 10) || 0;
				} else {
					console.log("2");
					return parseInt(limitObj[sourceType], 10) || 0;
				}
			} else {
				if (typeof limitObj[targetType] === "object") {
					console.log("3");
					return parseInt(limitObj[targetType][targetType], 10) || 0;
				} else {
					console.log("4");
					return parseInt(limitObj[targetType], 10) || 0;
				}
			}
		};

		const sourceLimit = getLimit(connectionLimit, data.type, true, targetHandleField);
		const targetLimit =
			getLimit(connectionLimit, data.type, false, targetHandleField) ||
			getLimit(connectionLimit, data.type, false, targetHandleField.toLowerCase());
		if (sourceLimit === 0 || targetLimit === 0) {
			onEdgeClick(id);
			error(`${data.title} cannot connect to ${targetHandleField} because connections to this type are not allowed!`);
			return false;
		}

		// Find nodes by type
		const sourceNodes = nodes.filter((node) => node.data.type === data.type);
		const targetNodes = nodes.filter((node) => node.data.type === targetHandleField);

		// Get all connected edges for the found nodes
		const connectedEdges = getConnectedEdges(sourceNodes.concat(targetNodes), edges);

		// Filter connections by source type and target type
		const sourceConnections = connectedEdges.filter(
			(edge) => edge.source.includes(data.type) && edge.target === connection.target,
		);

		if (sourceConnections.length >= sourceLimit && sourceLimit !== -1) {
			onEdgeClick(id);
			error(`Limit Reached for ${data.type} to connect to ${connection.target}`);
			return false;
		}

		// Handle handleDependency if it's an object or string
		let handleField = handleDependency;
		if (typeof handleDependency === "object") {
			handleField = Object.keys(handleDependency).find((key) => handleDependency[key].includes(targetHandleField));
		}

		// Initialize handleField as an array if it doesn't exist
		if (!Array.isArray(data[handleField])) {
			data[handleField] = [];
		}
		console.log("data:han ", data[handleField]);

		console.log("data:han ", connection.target);
		// Add the target connection if it doesn't already exist in the array
		if (!data[handleField].includes(connection.target)) {
			data[handleField].push(connection.target);
		}
		console.log("data:han ", data[handleField]);
		console.log("data: ", data);
		console.log("handleField: ", handleField);
		console.log("connectionLimit: ", connectionLimit);

		data.onOpenPopup(data, "connect");

		return true;
	};

	return {
		handleConnect,
		extractMultipleTitlesByType,
		onDeleteNode,
		extractOptions,
		getNodes,
	};
}