import {
    HdrAuto as HdrAutoIcon,
    Sensors as SensorsIcon,
    LeakAdd as LeakAddIcon,
    Person as PersonIcon,
    PlayForWork as PlayForWorkIcon,
    SwapHoriz as SwapHorizIcon,
    Policy as PolicyIcon,
    Folder as FolderIcon,
    GroupWork as GroupWorkIcon,
    Http as HttpIcon,
    InterpreterMode as InterpreterModeIcon,
    Translate as TranslateIcon,
    Flag as FlagIcon,
    Event as EventIcon,
    MenuBook as MenuBookIcon,
    DynamicForm as DynamicFormIcon,
    AllOut as AllOutIcon,
    SmartToy as SmartToyIcon,
    AirlineStops as AirlineStopsIcon,
    Workspaces as WorkspacesIcon,
    EmojiEvents as EmojiEventsIcon,
    Code as CodeIcon,
    ControlCamera as ControlCameraIcon,
    Link as LinkIcon,
    RestartAlt as RestartAltIcon,
} from "@mui/icons-material";

// Mapping of icon identifiers to components
export const iconComponents = {
    HdrAutoIcon,
    SensorsIcon,
    LeakAddIcon,
    PersonIcon,
    PlayForWorkIcon,
    SwapHorizIcon,
    PolicyIcon,
    FolderIcon,
    GroupWorkIcon,
    HttpIcon,
    InterpreterModeIcon,
    TranslateIcon,
    FlagIcon,
    EventIcon,
    MenuBookIcon,
    DynamicFormIcon,
    AllOutIcon,
    SmartToyIcon,
    AirlineStopsIcon,
    WorkspacesIcon,
    EmojiEventsIcon,
    CodeIcon,
    ControlCameraIcon,
    LinkIcon,
    RestartAltIcon,
    default: SensorsIcon, // Default icon if none matches
};
