/**
 * Validate the structure and content of a DSL JSON for Blend DSL models.
 */
export function validateNodeTypes(dslJson) {
    const nodeTypes = dslJson.nodeTypes;
    if (!nodeTypes || typeof nodeTypes !== "object") {
        throw new Error("Missing or invalid 'nodeTypes' in DSL JSON.");
    }

    const requiredNodeTypeFields = [
        "title",
        "icon", // Icon is conditionally required
        "draggable",
        "startPattern",
        "endKeyword",
    ];
    
    for (const [nodeTypeName, nodeType] of Object.entries(nodeTypes)) {
        // Create a dynamic list of required fields
        const dynamicRequiredFields = [...requiredNodeTypeFields];
    
        // If 'nestedNodeTypes' exists, 'icon' is not required
        if (nodeType.nestedNodeTypes) {
            const iconIndex = dynamicRequiredFields.indexOf("icon");
            if (iconIndex > -1) {
                dynamicRequiredFields.splice(iconIndex, 1); // Remove 'icon'
            }
        }
    
        // Validate required fields, excluding optional ones like 'nestedNodeTypes'
        validateRequiredFields(nodeTypeName, nodeType, dynamicRequiredFields);
        
        // Validate 'parameters' only if it exists
        if (nodeType.parameters) {
            validateParameters(`${nodeTypeName}.parameters`, nodeType.parameters);
        }
        // Validate 'nestedParameters'
        if (nodeType.nestedParameters) {
            validateNestedParameters(`${nodeTypeName}.nestedParameters`, nodeType.nestedParameters);
        }
    
        // Validate 'handles'
        if (nodeType.handles) {
            validateHandles(`${nodeTypeName}.handles`, nodeType.handles);
        }
    
        // Validate 'dependencies'
        if (nodeType.dependencies) {
            validateDependencies(`${nodeTypeName}.dependencies`, nodeType.dependencies);
        }
    
        // Validate patterns
        validatePatterns(nodeTypeName, nodeType);
    }
}    
/**
 * Ensure required fields are present in an object.
 */
function validateRequiredFields(context, object, requiredFields) {
    for (const field of requiredFields) {
        if (!object.hasOwnProperty(field)) {
            throw new Error(`Missing required field '${field}' in ${context}`);
        }
    }
}

/**
 * Validate parameters configuration.
 */
/**
 * Validate parameters configuration.
 * 'min', 'max', 'marks', and 'track' are optional for 'slider' inputType.
 */
/**
 * Validate parameters configuration.
 * 'name' is required, and at least one of 'default' or 'prefix' must exist.
 */
function validateParameters(context, parameters) {
    if (!Array.isArray(parameters)) {
        throw new Error(`'parameters' must be an array in ${context}`);
    }

    for (const parameter of parameters) {
        // 'name' is always required
        if (!parameter.name) {
            throw new Error(`Missing required 'name' in ${context}.`);
        }

        // At least one of 'default' or 'prefix' must exist
        if (!parameter.default && !parameter.prefix) {
            throw new Error(
                `Parameter '${parameter.name}' in ${context} must have at least one of 'default' or 'prefix'.`
            );
        }

        // Optional validations based on input type
        if (parameter.inputType === "autocomplete" && !parameter.options && !parameter.dependency) {
            throw new Error(`'autocomplete' inputType in ${context} requires 'options' or 'dependency'.`);
        }

        if (parameter.inputType === "slider") {
            // Validate optional attributes for sliders
            if (parameter.min && typeof parameter.min !== "number") {
                throw new Error(`'min' in ${context} must be a number.`);
            }
            if (parameter.max && typeof parameter.max !== "number") {
                throw new Error(`'max' in ${context} must be a number.`);
            }
            if (parameter.marks && !Array.isArray(parameter.marks)) {
                throw new Error(`'marks' in ${context} must be an array.`);
            }
            if (parameter.track && typeof parameter.track !== "boolean") {
                throw new Error(`'track' in ${context} must be a boolean.`);
            }
        }
    }
}



/**
 * Validate nested parameters configuration.
 */
function validateNestedParameters(context, nestedParameters) {
    if (typeof nestedParameters !== "object") {
        throw new Error(`'nestedParameters' must be an object in ${context}`);
    }

    for (const [nestedName, nestedFields] of Object.entries(nestedParameters)) {
        if (!Array.isArray(nestedFields)) {
            throw new Error(`'nestedParameters.${nestedName}' must be an array in ${context}`);
        }

        for (const nestedField of nestedFields) {
            // 'parameters' is optional, validate only if it exists
            if (nestedField.parameters) {
                if (!Array.isArray(nestedField.parameters)) {
                    throw new Error(
                        `'nestedParameters.${nestedName}.parameters' must be an array in ${context}`
                    );
                }
                nestedField.parameters.forEach((param, index) => {
                    if (typeof param !== "string") {
                        throw new Error(
                            `Invalid parameter '${param}' at index ${index} in 'nestedParameters.${nestedName}.parameters'`
                        );
                    }
                });
            }

            // Validate required fields excluding 'parameters'
            validateRequiredFields(`${context}.${nestedName}`, nestedField, ["name"]);
        }
    }
}


/**
 * Validate handle configuration.
 */
function validateHandles(context, handles) {
    if (!Array.isArray(handles)) {
        throw new Error(`'handles' must be an array in ${context}`);
    }

    for (const handle of handles) {
        validateRequiredFields(`${context}.handle`, handle, ["type"]);

        if (handle.style) {
            if (!handle.style.top || !handle.style.background) {
                throw new Error(
                    `Handle style in ${context} must include 'top' and 'background' properties.`
                );
            }
        }

        if (handle.connectionLimit && typeof handle.connectionLimit !== "object") {
            throw new Error(`'connectionLimit' must be an object in ${context}`);
        }
    }
}

/**
 * Validate dependencies configuration.
 */
/**
 * Validate dependencies configuration.
 * Ensures that each dependency is an object and contains at least one of 'parameter', 'format', or 'initFormat'.
 */
function validateDependencies(context, dependencies) {
    for (const [dependencyName, dependency] of Object.entries(dependencies)) {
        if (typeof dependency !== "object" || Array.isArray(dependency)) {
            throw new Error(`Dependency '${dependencyName}' in ${context} must be an object.`);
        }

        // At least one of 'parameter', 'format', or 'initFormat' must be present
        if (!dependency.parameter && !dependency.format && !dependency.initFormat) {
            throw new Error(
                `Dependency '${dependencyName}' in ${context} must have at least one of 'parameter', 'format', or 'initFormat'.`
            );
        }

        // If 'parameter' is present, ensure it is a string
        if (dependency.parameter && typeof dependency.parameter !== "string") {
            throw new Error(`'parameter' in dependency '${dependencyName}' in ${context} must be a string.`);
        }

        // If 'format' is present, ensure it is a string
        if (dependency.format && typeof dependency.format !== "string") {
            throw new Error(`'format' in dependency '${dependencyName}' in ${context} must be a string.`);
        }

        // If 'initFormat' is present, ensure it is a string
        if (dependency.initFormat && typeof dependency.initFormat !== "string") {
            throw new Error(`'initFormat' in dependency '${dependencyName}' in ${context} must be a string.`);
        }
    }
}


/**
 * Validate patterns like 'startPattern' and 'endKeyword'.
 */
function validatePatterns(nodeTypeName, nodeType) {
   // Validate 'startPattern': must be a string or an array
   if (!Array.isArray(nodeType.startPattern) && typeof nodeType.startPattern !== "string") {
    throw new Error(`'startPattern' in ${nodeTypeName} must be a string or an array of strings.`);
}

    // Validate 'endKeyword': must be a string or an array
    if (!Array.isArray(nodeType.endKeyword) && typeof nodeType.endKeyword !== "string") {
        throw new Error(`'endKeyword' in ${nodeTypeName} must be a string or an array of strings.`);
    }

    // Validate startPatternExceptions if they exist
    /**
     * Validate 'startPatternExceptions'.
     * Can be either an object or an array.
     */
    const startPatternExceptions = nodeType.startPatternExceptions;

    if (startPatternExceptions) {
        
        if (!Array.isArray(startPatternExceptions) && typeof startPatternExceptions !== "object") {
            throw new Error(
                `'startPatternExceptions' in ${nodeTypeName} must be an object or an array.`
            );
        }

        if (Array.isArray(startPatternExceptions)) {
            for (const exception of startPatternExceptions) {
                if (typeof exception !== "object" || !exception.goalType) {
                    throw new Error(
                        `Each exception in 'startPatternExceptions' array in ${nodeTypeName} must be an object and include 'goalType'.`
                    );
                }
            }
        } else if (typeof startPatternExceptions === "object") {
            for (const [key, exception] of Object.entries(startPatternExceptions)) {
                if (typeof exception !== "object" || !exception.goalType) {
                    throw new Error(
                        `Exception '${key}' in 'startPatternExceptions' object in ${nodeTypeName} must include 'goalType'.`
                    );
                }
            }
        }
    }
        

}
