import { IconComponent } from "../utils/transformer_obj_dsl";
import { transcriptToObjectsParsing } from "../utils/transformer_dsl_obj";

// Helper function to get dependencies array
const getDependenciesArray = (dependencies) => {
    return dependencies ? Object.keys(dependencies) : [];
};


// Helper function to replace placeholders in a string
const replacePlaceholders = (format, data) => {
    // console.log("format: ", format);
    // console.log("data: ", data);
    return format.replace(/\${(this\.\w+|\w+(\.\w+)*)}/g, (_, key) => {
        const keys = key.split('.');
        let value = data;
        for (let k of keys) {
            if (k === 'this') {
                value = value['this']; // Handle `this` context
            } else {
                value = value[k] !== undefined ? value[k] : "";
            }
        }
        return value;
    });
};


// Function to check visible conditions
const checkVisibleCondition = (condition={key:"", value:""}, data) => {
    return Object.entries(condition).every(([key, value]) => data[key] === value);
};

const createNodeWithDefaultData = (customNode, type, position, dslTextual, onOpenPopup, dslJson) => {
	const dslObject = transcriptToObjectsParsing(dslTextual, dslJson);
	const nodeType = dslJson.nodeTypes[type];
	
	

	let data = {};

	// Populate the data object with default values or empty strings
	if (nodeType.parameters) {
		nodeType.parameters.forEach(parameter => {
			const defaultValue = parameter.default !== undefined ? parameter.default : "";
			if (parameter.visibleCondition) {
				if (checkVisibleCondition(parameter.visibleCondition, data)) {
					data[parameter.name] = defaultValue;
				}
			} else {
				data[parameter.name] = defaultValue;
			}
		});
	}


	// console.log(data);

    let complexDependencies = [];
	let title = `${type}_${(dslObject[type] || []).length + 1}`;

	
	// Generate a unique title
	while (dslObject[type] && dslObject[type].some(item => item.title === title)) {
		title = `${type}_${(parseInt(title.split('_')[1]) + 1)}`;
	}
	data.title = title;

	
	if (nodeType.dependencies) {
		for (let [key, dependency] of Object.entries(nodeType.dependencies)) {
			let dependentNodes = dslObject[key] || [];

			// Ensure dependentNodes is an array
			if (!Array.isArray(dependentNodes)) {
				dependentNodes = [dependentNodes];
			}

			dependentNodes.forEach(dependentNode => {

				// Find the parameter name that has the same dependency as the key
				const parameterWithDependency = nodeType.parameters.find(param => param.dependency === key);
				const paramName = parameterWithDependency ? parameterWithDependency.name : key;

				if (dependency.initFormat) {
					// Prepare the data object for replacing placeholders
					const replaceData = {
						...dependentNode,
						this: data, // Directly include the current node's data under `this`
					};
					let value = replacePlaceholders(dependency.initFormat, replaceData);

					if (Array.isArray(data[paramName])) {
						data[paramName].push(value);
					} else {
						data[paramName] = value;
					}

				} else if (dependency.format) {
					complexDependencies.push(dependency.format);
				}
			});
		}
	}


	const dependenciesArray = getDependenciesArray(nodeType.dependencies);

	const node = {
		id: title ? title : type,
		type: customNode,
		position: position,
		data: {
			type: type,
			title: title,
			subtitle: nodeType.subtitle,
			icon: nodeType.icon ? <IconComponent iconIdentifier={nodeType.icon}/> : null,
			onOpenPopup: onOpenPopup,
			uniqueId: title,
			dependencies: dependenciesArray,
			handles: nodeType.handles || [],
			complexDependencies: complexDependencies,
			...data,
		},
		title: title,
	};

	return node;
};

const InitializeNodesEdges = (customNode, dslTextual, onOpenPopup, dslJson) => {
	const dslObject = transcriptToObjectsParsing(dslTextual, dslJson);
	// console.log("dslObject: ", dslObject);

	let nodes = [];
	let edges = [];
	let edgeId = 1;
	const basePosition = { x: 100, y: 100 };
	const xOffset = 400;
	const yOffset = 70;
	const nestedXOffset = 50; // Reduced offset for nested nodes

	const positionTracker = {};
	const hyperData = {};

	const getNextPosition = (type, index, isNested = false) => {
		const offset = isNested ? nestedXOffset : xOffset;

		if (!positionTracker[type]) {
			const typeIndex = Object.keys(positionTracker).length;
			positionTracker[type] = { x: basePosition.x + typeIndex * offset, y: basePosition.y };
		}

		const position = {
			x: positionTracker[type].x,
			y: positionTracker[type].y + index * yOffset,
		};

		positionTracker[type].y += yOffset;

		return position;
	};

	Object.keys(dslObject).forEach((type) => {
		const nodeType = dslJson.nodeTypes[type];
		const isDraggable = nodeType.draggable !== false;
		const dependenciesArray = getDependenciesArray(nodeType.dependencies);
		const complexDependencies = {};
	
		// Check for complex dependencies
		if (nodeType.dependencies && typeof nodeType.dependencies === "object") {
			for (const key in nodeType.dependencies) {
				if (nodeType.dependencies.hasOwnProperty(key)) {
					const dependency = nodeType.dependencies[key];
					if (dependency.parameter || dependency.format) {
						complexDependencies[key] = { format: dependency.format, parameter: dependency.parameter };
					}
				}
			}
		}
	
		if (!isDraggable) {
			hyperData[type] = {
				...dslObject[type],
				dependencies: dependenciesArray,
				complexDependencies: complexDependencies,
				maxInstances: nodeType.maxInstances,
			};
			return;
		}
	
		dslObject[type].forEach((entity, index) => {
			let { title } = entity;
	
			// Check if the title does not exist
			if (!title) {
				// Create a title from the dslObject[type].title and convert it to lowercase
				title = nodeType.title.toLowerCase();
				
				// Add the newly created title to the entity
				entity.title = title;
			}
	
			// Check if this nodeType is targeted by another nodeType
			let parentNode = null;
			let extent = null;
			let style = null;
			let isNested = false; // To check if the node is nested
			Object.keys(dslJson.nodeTypes).forEach((parentType) => {
				const parentNodeType = dslJson.nodeTypes[parentType];
				if (parentNodeType.nestedNodeTypes && parentNodeType.nestedNodeTypes.includes(type)) {
					const parentEntities = dslObject[parentType];
					if (parentEntities && parentEntities.length > 0) {
						// Iterate through parentEntities to find the correct parentTitle
						parentEntities.forEach((parentEntity) => {
							// Check if the current entity's parent matches the current parentEntity's title
							if (entity.parent === parentEntity.type) {
								// Assign parentNode and extent correctly
								if(entity.parentTitle) {
									if(entity.parentTitle === parentEntity.title){
										parentNode = parentEntity.parentTitle ? parentEntity.parentTitle : parentEntity.title;
										extent = "parent";
										isNested = true; // Mark the node as nested
									}
								}else{
									parentNode = parentEntity.parentTitle ? parentEntity.parentTitle : parentEntity.title;
									extent = "parent";
									isNested = true; // Mark the node as nested
								}
							}
						});
					}
				}
			});
	
			const position = getNextPosition(type, index,isNested);
			const group = nodeType.nestedNodeTypes?.length > 0;
			
			// Check if parameters is not an empty string and nestedParameters is not an empty object
			const hasParameters = nodeType.parameters && nodeType.parameters.length > 0;
			const hasNestedParameters = nodeType.nestedParameters && Object.keys(nodeType.nestedParameters).length > 0;

			const noInfo = hasParameters || hasNestedParameters;
			entity.noInfo = noInfo;
			if (group) {
				entity.label = type;
				entity.group = group;
				style = { backgroundColor: 'rgb(163, 39, 241,0.4)', width: 500, height: 300 };
			}
	
			nodes.push({
				id: title ? title : type,
				type:  customNode,
				position,
				data: {
					icon: nodeType.icon ? <IconComponent iconIdentifier={nodeType.icon}/> : null,
					onOpenPopup: onOpenPopup,
					uniqueId: title,
					dependencies: dependenciesArray,
					complexDependencies: complexDependencies,
					handles: nodeType.handles || [],
					maxInstances: nodeType.maxInstances,
					...entity,
				},
				draggable: isDraggable,
				style,
				extent,
				parentNode,  // Add parentNode if a parent node is found
			});

			

			if (nodeType.handles) {
				// Iterate through each handle configuration
				nodeType.handles.forEach(handle => {
					
					// Handle parameter dependencies
					if (handle.parameterDependency) {
						const handleField = handle.parameterDependency;
						const handleSourceId = `${handle.type}-${handle.parameterDependency ? handle.parameterDependency : data.type.toLowerCase()}`;
						const targetNodeType = nodeType.title.toLowerCase();
			
						const processDependency = (key, value) => {
							if (Array.isArray(value)) {
								value.forEach((subValue) => {
									if (typeof subValue === "object" && subValue !== null) {
										Object.keys(subValue).forEach((subKey) => {
											processDependency(subKey, subValue[subKey]);
										});
									} else {
										let targetNode = null;
										if (dslObject[targetNodeType]) {
											targetNode = dslObject[targetNodeType].find((node) => node.title === subValue);
										}
										if (!targetNode) {
											// Try to find the target node in other node types
											for (const otherType in dslObject) {
												if (otherType !== targetNodeType && Array.isArray(dslObject[otherType])) {
													targetNode = dslObject[otherType].find((node) => node.title === subValue);
													if (targetNode) break;
												}
											}
										}
										if (targetNode) {
											edges.push({
												id: `e${edgeId++}_${key}`,
												source: title,
												sourceHandle: handleSourceId,
												target: targetNode.title,
											});
										}
									}
								});
							} else {
								let targetNode = null;
								if (dslObject[targetNodeType]) {
									targetNode = dslObject[targetNodeType].find((node) => node.title === value);
								}
								if (!targetNode) {
									// Try to find the target node in other node types
									for (const otherType in dslObject) {
										if (otherType !== targetNodeType && Array.isArray(dslObject[otherType])) {
											targetNode = dslObject[otherType].find((node) => node.title === value);
											if (targetNode) break;
										}
									}
								}
								if (targetNode) {
									edges.push({
										id: `e${edgeId++}_${key}`,
										source: title,
										sourceHandle: handleSourceId,
										target: targetNode.title,
									});
								}
							}
						};
			
						// Process dependencies if handleField is an array
						if (Array.isArray(handleField)) {
							handleField.forEach((parameter) => {
								if (entity[parameter]) {
									finalTargetNode = processDependency(parameter, entity[parameter]);
								}
							});
						} else {
							// Handle nested fields (e.g., goals.goals)

							const handleFieldParts = handleField.split('.');

							let fieldValue = entity;
							let isNested = true;

							// Traverse the nested structure
							for (let i = 0; i < handleFieldParts.length; i++) {
								const part = handleFieldParts[i];

								if (Array.isArray(fieldValue)) {
									// If the current fieldValue is an array, we need to collect all values of the next part
									fieldValue = fieldValue.map(item => item[part]).filter(item => item !== undefined);
								} else if (fieldValue && fieldValue[part] !== undefined) {
									fieldValue = fieldValue[part];
								} else {
									isNested = false;
									break;
								}
							}

							if (isNested) {
								let parseParameters = [];

								// If the final fieldValue is an array, we need to flatten it
								if (Array.isArray(fieldValue)) {
									fieldValue.forEach((dependency) => {
										if (typeof dependency === "string") {
											parseParameters.push(dependency);
										} else if (typeof dependency === "object" && dependency !== null) {
											Object.values(dependency).forEach(value => {
												if (typeof value === "string") {
													parseParameters.push(value);
												}
											});
										}
									});
								} else if (typeof fieldValue === "string") {
									parseParameters.push(fieldValue);
								} else if (typeof fieldValue === "object" && fieldValue !== null) {
									Object.values(fieldValue).forEach(value => {
										if (typeof value === "string") {
											parseParameters.push(value);
										}
									});
								}

								// Now search for nodes that match the goal titles
								parseParameters.forEach(parseParameter => {
									let targetNode = null;

									// Check within the same node type
									if (dslObject[targetNodeType]) {
										targetNode = dslObject[targetNodeType].find((node) => node.title === parseParameter);
									}

									const handleTargets = Array.isArray(handle.handleTarget) ? handle.handleTarget : [handle.handleTarget];

									// Enhanced logic to handle handleTarget with nested fields
									handleTargets.forEach(handleTarget => {
										if (!targetNode && handleTarget && typeof handleTarget === 'object') {
											const { nodeType, parameter } = handleTarget;
											const parameterParts = parameter.split('.');

											if (dslObject[nodeType] && Array.isArray(dslObject[nodeType])) {
												dslObject[nodeType].forEach(node => {
													let nestedValue = node;
													let match = true;

													for (let i = 0; i < parameterParts.length; i++) {
														if (Array.isArray(nestedValue)) {
															nestedValue = nestedValue.map(item => item[parameterParts[i]]).filter(item => item !== undefined);
														} else if (nestedValue && nestedValue[parameterParts[i]] !== undefined) {
															nestedValue = nestedValue[parameterParts[i]];
														} else {
															match = false;
															break;
														}
													}

													if (match && Array.isArray(nestedValue)) {
														nestedValue.forEach(innerItem => {
															if (innerItem === parseParameter) {
																targetNode = node;
															} else if (innerItem && innerItem.name === parseParameter) {
																targetNode = node;
															}
														});
													} else if (match && nestedValue === parseParameter) {
														targetNode = node;
													}
												});
											}
										}
									});

									if (!targetNode) {
										// Try to find the target node in other node types
										for (const otherType in dslObject) {
											if (otherType !== targetNodeType && Array.isArray(dslObject[otherType])) {
												targetNode = dslObject[otherType].find((node) => node.title === parseParameter);
												if (targetNode) break;
											}
										}
									}

									if (targetNode) {
										
										edges.push({
											id: `e${edgeId++}_${handleField}`,
											source: title,
											sourceHandle: handleSourceId,
											target: targetNode.title,
										});
									}
								});
							} else {
								// console.log(`entity[${handleField}] is undefined or not an array`);
							}

						}
					}
				});
			}
		});
	});

	// console.log("nodes: ", nodes);
	// console.log("edges: ", edges);

	return { nodes, edges, hyperData };
};

export { InitializeNodesEdges, createNodeWithDefaultData, getDependenciesArray };
