import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/system";

const MaterialUISwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 42, // from IOSSwitch
	height: 26, // from IOSSwitch
	padding: 0, // from IOSSwitch
	"& .MuiSwitch-switchBase": {
		padding: 0, // from IOSSwitch
		margin: 2, // from IOSSwitch
		transitionDuration: "300ms", // from IOSSwitch
		"&.Mui-checked": {
			transform: "translateX(16px)", // from IOSSwitch
			color: "#fff", // common setting
			"& .MuiSwitch-thumb:before": {
				backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M13.5 7L17 10.5' stroke='%23ffffff' stroke-linecap='round'/%3E%3Cpath d='M7 13.5L10.5 17' stroke='%23ffffff' stroke-linecap='round'/%3E%3Cpath d='M10.5 7L7 10.5' stroke='%23ffffff' stroke-linecap='round'/%3E%3Cpath d='M17 13.5L13.5 17' stroke='%23ffffff' stroke-linecap='round'/%3E%3Ccircle cx='12' cy='5.5' r='2' stroke='%23ffffff'/%3E%3Ccircle cx='12' cy='18.5' r='2' stroke='%23ffffff'/%3E%3Ccircle cx='5.5' cy='12' r='2' stroke='%23ffffff'/%3E%3Ccircle cx='18.5' cy='12' r='2' stroke='%23ffffff'/%3E%3Ccircle cx='5.5' cy='12' r='0.5' stroke='%23ffffff'/%3E%3Ccircle cx='12' cy='18.5' r='0.5' stroke='%23ffffff'/%3E%3C/svg%3E")`,
			},
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466", // from IOSSwitch, but you can choose any color
				opacity: 1, // from IOSSwitch
				border: 0, // from IOSSwitch
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5, // from IOSSwitch
			},
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box", // from IOSSwitch
		width: 22, // from IOSSwitch
		height: 22, // from IOSSwitch
		backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
		"&::before": {
			content: "''",
			position: "absolute",
			width: "100%",
			height: "100%",
			left: 0,
			top: 0,
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center",
			backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12 3V21M9 21H15M19 6V3H5V6' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`,
		},
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2, // from IOSSwitch
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D", // from IOSSwitch
		opacity: 1, // common setting
		transition: theme.transitions.create(["background-color"], {
			// from IOSSwitch
			duration: 500,
		}),
	},
}));

const WhiteFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
	"& .MuiFormControlLabel-label": {
		color: theme.palette.common.white, // Sets the label text color to white
	},
}));

// Exporting directly for use in other components
export { WhiteFormControlLabel, MaterialUISwitch };
