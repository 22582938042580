const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

const createRegexFromPattern = (pattern) => {
    let regexPattern = pattern;
    const fields = [];
    let match;
    const regexFieldPattern = /\${(\w+)}/g;
    while ((match = regexFieldPattern.exec(pattern)) !== null) {
        fields.push({ name: match[1], order: fields.length + 1 });
    }
    const regex = new RegExp(regexPattern.replace(/\$\{(\w+)}/g, "(\\w+)"));
    return { regex, fields };
};

const normalizeToArray = (input) => {
    return Array.isArray(input) ? input : [input];
};

const matchPattern = (line, patterns, exceptions = []) => {
    exceptions = normalizeToArray(exceptions);
    patterns = normalizeToArray(patterns);
    for (let i = 0; i < patterns.length; i++) {
        const pattern = patterns[i];
        const { regex, fields } = createRegexFromPattern(pattern);
        const match = line.match(regex);

        if (match) {
            let isException = false;
            for (const exception of exceptions) {
                if (exception.order === undefined || exception.order === i + 1) {
                    let exceptionField = {};
                    Object.keys(exception).forEach(key => {
                        for (const field of fields) {
                            if (key === field.name) {
                                exceptionField = field;
                            }
                        }
                    });

                    if (exceptionField && match[exceptionField.order] === exception[exceptionField.name]) {
                        return { match, fields };
                    } else {
                        isException = true;
                        continue;
                    }
                }
            }
            if (!isException) {
                return { match, fields };
            }
        }
    }
    return null;
};

const parseValue = (value, type, inputType, multiple, escapeSpecialCharacters = false) => {
    switch (type) {
        case "int":
            return parseInt(value, 10);
        case "float":
            return parseFloat(value);
        case "boolean":
            if (typeof value === "string") {
                if (!escapeSpecialCharacters) value = value.replace(/['"]+/g, "");
                return value.toLowerCase() === "true";
            }
            return Boolean(value);
        case "string":
        default:
            if (inputType === "autocomplete" && multiple) {
                return value
                    .split(",")
                    .map((item) => !escapeSpecialCharacters ? item.trim().replace(/['"]+/g, "") : item.trim());
            } else {
                return !escapeSpecialCharacters ? value.replace(/['"]+/g, "") : value; // Preserve quotes based on the flag
            }
    }
};

const parseParameters = (line, parameters, currentNode, currentNodeType, multiLineField, multiLineFieldValue, dslJson) => {
    for (const parameter of parameters) {
        let prefixPattern;
        const exceptionField = currentNodeType.parameterException;

        if (parameter.prefix !== "" && parameter.startDelimiter && parameter.endDelimiter) {
            prefixPattern = new RegExp(`^${parameter.prefix}\\s*${parameter.startDelimiter}(.*)${parameter.endDelimiter}$`);
        } else if (parameter.prefix !== "") {
            prefixPattern = new RegExp(`^${parameter.prefix}\\s*(.*)$`);
        } else if (parameter.delimiter === "") {
            prefixPattern = new RegExp(`^(.*?)\\s+(.*)$`);
        } else if (parameter.delimiter) {
            prefixPattern = new RegExp(`^(.*?)${parameter.delimiter}(.*)$`);
        } else {
            prefixPattern = new RegExp(`^(".*?"|[^"]*)$`);
        }

        const match = line.match(prefixPattern);

        if (match && exceptionField && exceptionField === parameter.name) {
            const expectedValue = parameter.default;
            if (match[1] !== expectedValue) {
                currentNode.type = match[1];
                currentNode[exceptionField] = match[1];
                currentNodeType = dslJson.nodeTypes[match[1]];
                continue;
            }
        }

        if (match) {
            if (parameter.multiLine) {
                multiLineField = parameter.name;
                multiLineFieldValue.push(match[1].trim());
            } else if (parameter.type === "array") {
                currentNode[parameter.name] = match[1].split(" ").map((item) => item.trim());
            } else {
                currentNode[parameter.name] = parseValue(match[1], parameter.type, parameter.inputType, parameter.multiple, parameter.escapeSpecialCharacters);
            }
            break;
        } else if (parameter.prefix === "" && parameter.delimiter === "") {
            const parts = line.split(/\s+/);
            if (parts.length === 2) {
                currentNodeType.parameters.forEach((parameter) => {
                    if (parameter.prefix === "" && parameter.delimiter === "") {
                        currentNode[parameter.name] = parts[0];
                        currentNode[parameter.parameters[1]] = parts[1];
                    }
                });
            }
        }
    }

    return { multiLineField, multiLineFieldValue, currentNodeType };
};

const parseNestedParameters = (line, currentNodeType, currentNode, currentNestedField, resetNestedField, lines, lineIndex,dslJson) => {
    const nestedParameters = currentNodeType.nestedParameters
    const parameters = currentNodeType.parameters
    const nestedFieldDefinition = nestedParameters[currentNestedField];
    let matchedNestedField = false;

    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapes special characters for use in a regex pattern
    };
    
    for (const parameter of nestedFieldDefinition) {
        let prefixPattern;
        // Case 1: No prefix, no delimiter, but with an endDelimiter
        if (parameter.prefix === "" && !parameter.delimiter && parameter.endDelimiter) {
            prefixPattern = new RegExp(`^\\s*(.*?)(?:${escapeRegExp(parameter.endDelimiter)}\\s*)?$`);
        // Case 2: Prefix, delimiter, and endDelimiter are all defined
        } else if (parameter.prefix !== "" && parameter.delimiter && parameter.endDelimiter) {
            prefixPattern = new RegExp(`^${escapeRegExp(parameter.prefix)}\\s*${parameter.delimiter}(.*)${parameter.endDelimiter}$`);
        // Case 3: Only prefix is defined, no delimiter or endDelimiter
        } else if (parameter.prefix !== "") {
            prefixPattern = new RegExp(`^${escapeRegExp(parameter.prefix)}\\s*(.*)$`);
        // Case 4: Delimiter is an empty string, split by whitespace
        } else if (parameter.delimiter === "") {
            prefixPattern = new RegExp(`^(.*?)\\s+(.*)$`);
        // Case 5: No delimiter and no prefix
        } else if (!parameter.delimiter && parameter.prefix === "") {
            prefixPattern = new RegExp(`^(.*)$`);
        // Default case: Delimiter is defined but prefix is empty
        } else {
            prefixPattern = new RegExp(`^(.*?)${escapeRegExp(parameter.delimiter)}(.*)$`);
        }
        
        let match = line.match(prefixPattern);

        if (!match && parameter.empty) {
            prefixPattern = new RegExp(`^(.*?)${parameter.delimiter}?$`);
            match = line.match(prefixPattern);
            if (match) {
                match[2] = "";
            }
        }

        if (match) {
            let fieldValue = parseValue(match[1], parameter.type, parameter.inputType, parameter.multiple, parameter.escapeSpecialCharacters);
            let secondPart = match[2] ? match[2].trim() : "";

            if (parameter.multiline && !secondPart) {     
                let nestedLines = "";
                while (
                    lines[lineIndex + 1] &&
                    !Object.keys(nestedParameters).some(nestedKey => lines[lineIndex + 1].trim().startsWith(nestedKey)) &&
                    !parameters.some(param => lines[lineIndex + 1].trim().startsWith(param.prefix))
                ) {
                    lineIndex++;
                    let nextLine = lines[lineIndex].trim();
                    // Normalize endKeyword to an array
                    const endKeywords = normalizeToArray(currentNodeType.endKeyword);
                    if (nextLine.includes(parameter.delimiter) || endKeywords.some(keyword => nextLine.includes(keyword))) {
                        currentNode[currentNestedField].push({
                            [parameter.parameters[0]]: match[1].trim(),
                            [parameter.parameters[1]]: nestedLines,
                        });
                        lineIndex--;
                        break;// extra -1 to account for the increment in the outer loop
                    }
                    nestedLines += `${nextLine} `;
                }
                break;
            }

            if (resetNestedField && nestedParameters && fieldValue) {
            
                const nestedKey = Object.keys(nestedParameters).find(nestedKey => 
                    Array.isArray(fieldValue)
                        ? fieldValue.some(value => value.startsWith(nestedKey))
                        : fieldValue.startsWith(nestedKey)
                );
                
                if (nestedKey) {
                    resetNestedField();
                    currentNestedField = null;
                    return { matchedNestedField: false, newLineIndex: lineIndex }; // Reset and exit to re-evaluate the current line
                }
            }

            // Check if the field contains nested node types
            if (parameter.nestedNodeTypes) {
                const nestedNodes = parseNestedNodeTypes(lines, dslJson, lineIndex, currentNode.type, resetNestedField);
                matchedNestedField = true;
                if (nestedNodes.nodes.length > 0 && parameter.parameters[0]) {
                    // Extract titles from the nested nodes
                    const titlesArray = nestedNodes.nodes.map(node => ({ [parameter.parameters[0]]: node.title }));

                    // Save the titles array in the current node
                    currentNode[currentNestedField] = titlesArray;
                }else{
                    // console.error(`No nested nodes found for field: ${currentNestedField}`);
                }
                break;
            } else {
                // Regular nested field parsing
                if (parameter.inputType === "autocomplete") {
                    currentNode[currentNestedField] = currentNode[currentNestedField].concat(fieldValue);
                } else {
                    if (parameter.delimiter && parameter.parameters[1] && parameter.parameterStart !== null) {
                        const parts = fieldValue.split(parameter.delimiter);
                        const fieldObject = {};
                        fieldObject[parameter.parameters[0]] = parts[0].trim();
                        if (parameter?.endDelimiter) {
                            fieldObject[parameter.parameters[1]] = parts[1].replace(parameter.endDelimiter, "").trim();
                        } else {
                            fieldObject[parameter.parameters[1]] = parts[1].trim();
                        }
                        currentNode[currentNestedField].push(fieldObject);
                    } else if (parameter?.parameters?.length === 2) {
                        currentNode[currentNestedField].push({
                            [parameter.parameters[0]]: match[1].trim(),
                            [parameter.parameters[1]]: match[2].trim(),
                        });
                    } else {
                        currentNode[currentNestedField].push({
                            [parameter.name]: fieldValue,
                        });
                    }
                }
                matchedNestedField = true;
                break;
            }
        }
    }

    return { matchedNestedField, newLineIndex: lineIndex };
};

const parseNestedNodeTypes = (lines, dslJson, startLine, parentType = null, resetNestedField, parentTitle = null) => {
    const parsedNodes = [];
    const parentNode = dslJson.nodeTypes[parentType];
    let currentNode = null;
    let currentNodeType = null;
    let multiLineField = null;
    let multiLineFieldValue = [];
    let foundNestedNodeType = null;
    let foundParameters = false;
    let currentNestedField = null; // Track the current nested field if processing nested parameters
    let lineIndex = startLine;

    while (lineIndex < lines.length) {
        const line = lines[lineIndex];

        if (!currentNode) {
            for (const nestedTypeName of parentNode.nestedNodeTypes || []) {
                const nestedType = dslJson.nodeTypes[nestedTypeName];
                const matchResult = matchPattern(line, [nestedType.startPattern]);
                if (matchResult) {
                    const { match, fields } = matchResult;
                    currentNodeType = nestedType;
                    currentNode = {
                        type: nestedTypeName,
                        title: fields.find(field => field.name === 'title')?.value || nestedType.title,
                        parent: parentType,
                        parentTitle: parentTitle,
                        nestedNodes: []
                    };
                    fields.forEach((field, index) => {
                        currentNode[field.name] = match[index + 1];
                    });
                    break;
                }
            }
            if (!currentNode) {
                const parentEndKeywords = normalizeToArray(parentNode?.endKeyword);
            
                if (parentEndKeywords.includes(line)) {
                    break;
                } 
                console.log(`No match found for nested line: ${line}`);
            }
        } else {
            const endKeywords = normalizeToArray(currentNodeType.endKeyword);

            if (endKeywords.includes(line)) {
                // Combine the current node with its nested nodes
                const combinedNodes = [currentNode, ...currentNode.nestedNodes];
                combinedNodes.forEach(node => parsedNodes.push(node));
                currentNode = null;
                currentNodeType = null;
                currentNestedField = null;
            } else {
                if(currentNodeType.nestedNodeTypes && currentNodeType.nestedNodeTypes.length > 0) {
                    const nestedNodeTypeNames = currentNodeType.nestedNodeTypes.map(type => dslJson.nodeTypes[type].startPattern);
                    foundNestedNodeType = matchPattern(line, nestedNodeTypeNames)
                }
                if (currentNodeType.parameters && currentNodeType.parameters.length > 0) {
                    foundParameters = currentNodeType.parameters.some(parameter => line.startsWith(parameter.prefix));
                }
                
                if (foundNestedNodeType) {
                    const nestedNodes = parseNestedNodeTypes(lines, dslJson, lineIndex, currentNode.type, resetNestedField, currentNode.title);
                    currentNode.nestedNodes.push(...nestedNodes.nodes);
                    lineIndex = nestedNodes.endLine; 
                } else if (foundParameters){
                    const result = parseParameters(line, currentNodeType.parameters, currentNode, currentNodeType, multiLineField, multiLineFieldValue, dslJson);
                    multiLineField = result.multiLineField;
                    multiLineFieldValue = result.multiLineFieldValue;
                    currentNodeType = result.currentNodeType;
                } else if (currentNodeType.nestedParameters && currentNestedField) {
                    const { matchedNestedField, newLineIndex } = parseNestedParameters(line, currentNodeType, currentNode, currentNestedField, resetNestedField, lines, lineIndex,dslJson);
                    lineIndex = newLineIndex; 

                    if (!matchedNestedField && !line.startsWith(currentNestedField + ":")) {
                        resetNestedField();
                        for (const nestedField of Object.keys(currentNodeType.nestedParameters || {})) {
                            if (line.startsWith(nestedField + ":")) {
                                currentNestedField = nestedField;
                                currentNode[nestedField] = [];
                                break;
                            }
                        }
                    }
                }  else if (currentNodeType.nestedParameters && !currentNestedField) {
                    for (const nestedField of Object.keys(currentNodeType.nestedParameters || {})) {
                        const parameterStart = currentNodeType.nestedParameters[nestedField][0]?.parameterStart;
                        const allFieldNames = Object.values(currentNodeType.parameters).flat().map(parameter => parameter.prefix);

                        if (line.startsWith(nestedField + ":") || (parameterStart === null && !allFieldNames.some(fieldName => line.includes(fieldName)))) {
                            currentNestedField = nestedField;
                            currentNode[nestedField] = [];
                            lineIndex--;
                            break;
                        }
                    }
                }
            }
        }
        lineIndex++;
    }

    return { nodes: parsedNodes, endLine: lineIndex - 1 };
};


const transcriptToObjectsParsing = (modelString, dslJson) => {
    const lines = modelString
    .split("\n")
    .map((line) => {
        line = line.trim();
        
        // Check if the line contains 'http://' or 'https://'
        const isUrl = line.includes('http://') || line.includes('https://');
        
        // If it's not a URL, split at '//' and take the part before it
        if (!isUrl) {
            line = line.split("//")[0].trim();
        }

        return line;
    })
    .filter((line) => line.length > 0);

    const objResults = {};
    let currentNode = null;
    let currentNodeType = null;
    let currentNestedField = null;
    let multiLineField = null;
    let multiLineFieldValue = [];

    for (const type in dslJson.nodeTypes) {
        objResults[type] = dslJson.nodeTypes[type].draggable === false ? null : [];
    }

    const handleEndOfNestedField = () => {
        currentNestedField = null;
    };
    
    for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
        const line = lines[lineIndex];
        if (!currentNode) {
            for (const [type, nodeType] of Object.entries(dslJson.nodeTypes)) {
                const startPatterns = normalizeToArray(nodeType.startPattern);
                const exceptions = normalizeToArray(nodeType.startPatternExceptions || []);
                const matchResult = matchPattern(line, startPatterns, exceptions);

                if (matchResult) {
                    const { match, fields } = matchResult;
                    currentNodeType = nodeType;
                    currentNode = { type: type, title: type };
                    fields.forEach((field) => {
                        currentNode[field.name] = match[field.order];
                    });
                    // console.log(`Matched node type: ${type}`, deepClone(currentNode));
                    break;
                }
            }

            if (!currentNode) {
                console.log(`No match found for line: ${line}`);
            }
        } else {
            const endKeywords = normalizeToArray(currentNodeType.endKeyword);

            if (endKeywords.includes(line)) {
                if (currentNodeType.draggable === false) {
                    objResults[currentNode.type] = currentNode;
                } else {
                    objResults[currentNode.type].push(currentNode);
                }

                if (multiLineField) {
                    currentNode[multiLineField] = multiLineFieldValue.join(" ");
                    multiLineField = null;
                    multiLineFieldValue = [];
                }

                currentNode = null;
                currentNodeType = null;
                currentNestedField = null;
                multiLineField = null;
                multiLineFieldValue = [];
                continue;
            }

            if (!currentNestedField) {
                for (const nestedField of Object.keys(currentNodeType.nestedParameters || {})) {
                    const parameterStart = currentNodeType.nestedParameters[nestedField][0]?.parameterStart;
                    const allFieldNames = Object.values(currentNodeType.parameters).flat().map(parameter => parameter.prefix);

                    if (line.startsWith(nestedField + ":") || (parameterStart === null && !allFieldNames.some(fieldName => line.includes(fieldName)))) {
                        currentNestedField = nestedField;
                        currentNode[nestedField] = [];
                        break;
                    }
                }
            }

            if (currentNodeType.nestedNodeTypes && currentNodeType.nestedNodeTypes.length > 0) {
                const nestedNodes = parseNestedNodeTypes(lines, dslJson, lineIndex, currentNode.type, handleEndOfNestedField);
                nestedNodes.nodes.forEach((node) => {
                    if (!objResults[node.type]) objResults[node.type] = [];
                    objResults[node.type].push(node);
                });
                lineIndex = nestedNodes.endLine - 1; // Subtract 1 to adjust for the outer loop increment
            } else if (currentNodeType.nestedParameters && currentNestedField) {
                const { matchedNestedField, newLineIndex } = parseNestedParameters(line, currentNodeType, currentNode, currentNestedField, handleEndOfNestedField, lines, lineIndex);
                lineIndex = newLineIndex;  // Update lineIndex if multiline parsing advanced it
            
                if (!matchedNestedField && !line.startsWith(currentNestedField + ":")) {
                    handleEndOfNestedField();
                    for (const nestedField of Object.keys(currentNodeType.nestedParameters || {})) {
                        if (line.startsWith(nestedField + ":")) {
                            currentNestedField = nestedField;
                            currentNode[nestedField] = [];
                            break;
                        }
                    }
                }
            } else {
                if (multiLineField) {
                    if (line.startsWith("  ") || !line.match(/^\w+:/)) {
                        multiLineFieldValue.push(line.trim());
                        continue;
                    } else {
                        currentNode[multiLineField] = multiLineFieldValue.join(" ");
                        multiLineField = null;
                        multiLineFieldValue = [];
                    }
                }

                const result = parseParameters(line, currentNodeType.parameters, currentNode, currentNodeType, multiLineField, multiLineFieldValue, dslJson);
                multiLineField = result.multiLineField;
                multiLineFieldValue = result.multiLineFieldValue;
                currentNodeType = result.currentNodeType;
            }
        }
    }

    for (const type in objResults) {
        if (Array.isArray(objResults[type]) && objResults[type].length === 0) {
            delete objResults[type];
        } else if (objResults[type] === null && dslJson.nodeTypes[type].draggable === false) {
            delete objResults[type];
        }
    }

    console.log("objResults dsl to json",deepClone(objResults));
    return objResults;
};

export { transcriptToObjectsParsing };
