import React, { useState, useEffect } from "react";
import { TextField, Button, MenuItem } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	dynamicInputs: {
		display: "flex",
		alignItems: "center",
		gap: "10px",
		marginBottom: "10px",
		marginTop: "10px",
	},
	dynamicInputs__input: {
		backgroundColor: "#a9c3d2",
		color: "#000",
		borderRadius: "4px",
		marginBottom: "10px",
		"&:hover": {
			backgroundColor: "#fff",
			opacity: 0.8,
		},
	},
	labelWithLine: {
		color: "#fff",
		position: "relative",
		display: "block", 
		marginBottom: "10px", 
		paddingBottom: "5px", 
		borderBottom: "2px solid #fff", 
		paddingRight: "10px",
		paddingLeft: "10px", 
		backgroundColor: "transparent",
	},
	addButton: {
		backgroundColor: "#a9c3d2",
		color: "#000",
		marginBottom: "10px",
		transition: "all 0.2s linear",
		"&:hover": {
			backgroundColor: "#D1FFB5",
			opacity: 0.8,
			transition: "all 0.2s linear",
		},
	},
	removeButton: {
		backgroundColor: "#a9c3d2",
		color: "#ff0000",
		padding: "0px",
		width: "30px",
		height: "30px",
		minWidth: "20px",
		marginBottom: "7px",
		borderRadius: "100px",
		transition: "all 0.2s linear",
		"&:hover": {
			transition: "all 0.2s linear",
			backgroundColor: "#FE6F6F",
			color: "#fff",
		},
	},
}));

// Function to parse group relationships and update existingIds based on circular references
function parseAndCheckGroups(values, current_value, existingIds, complexLabels, counter, prefix) {
	// Check each group for circular references
	const attributeName = `${prefix}_id`;
	const targetGroup = current_value[attributeName];
	let tempTargetGroup = "";
	values.forEach((value, index) => {
		complexLabels.forEach((complexLabel) => {
			const targetId = value[complexLabel];
			if (targetId === targetGroup) {
				existingIds.add(value[attributeName]);
				tempTargetGroup = value[attributeName];
			}
			if (tempTargetGroup === targetId) {
				existingIds.add(value[attributeName]);
				tempTargetGroup = value[attributeName];
				
			}
			
		});
	});
}

function funcAddOptions(length, counter, values, value, ac, complexLabels, label) {
	
	// Adjust counter based on presence, defaulting to length if counter is not provided
	counter = counter ? counter : length;

	let newInputOptions = [];
	let attributeName, valueName, filteredComplexLabel;
	const parts = ac.split("_");
	const prefix = parts[0];

	

	// Determine the appropriate attribute names
	attributeName = label ? label : `${prefix}_id`;
	valueName = value[attributeName];

	// Create a set of existing ids to avoid duplicates
	const existingIds = new Set(values.map((value) => value[attributeName]));

	// Optionally, remove the current value from the set of existing IDs
	if (existingIds.has(valueName)) {
		existingIds.delete(valueName);
	}

	// Add on the existing IDs for prefix value if complexLabel is provided
	if (complexLabels) {
		// Determine the appropriate attribute names
		attributeName = `${prefix}_id`;
		valueName = value[attributeName];
		existingIds.add(valueName);

		if (complexLabels.length > 1) {
			filteredComplexLabel = complexLabels.filter((item) => item !== label);

			const existingComplexIds = new Set(values.map((value) => value[filteredComplexLabel]));

			existingComplexIds.forEach((id) => existingIds.add(id));
		}

		// Parse and check groups for circular references
		parseAndCheckGroups(values, value, existingIds, complexLabels, counter, prefix);
	}

	// Generate and append new options to the inputOptions array
	let i = 1;
	while (i <= counter) {
		const newId = i;
		const newOptionValue = `${prefix}_${newId}`;

		if (!existingIds.has(newOptionValue)) {
			const newOption = {
				value: newOptionValue,
				label: newOptionValue,
			};
			newInputOptions.push(newOption);
		} else {
		}

		i++;
	}

	return newInputOptions;
}


const DynamicInputs = ({
	id,
	types,
	values,
	options,
	connectedLabels,
	label,
	labels,
	handleChange,
	onAddAction,
	onRemoveAction,
	validationFlag,
	disabledFields = [],
	complexLabel,
	affectCounter,
	counter,
	flex,
	requiredLabels,
}) => {
	const classes = useStyles();
	const [selectedType, setSelectedType] = useState("");
	const [indexOptions, setIndexOptions] = useState(-1);
	const [errors, setErrors] = useState([]);
	const [validationLabel, setValidationLabel] = useState("");

	// Define indices at a broader scope
	let triggerLabelIndex = -1;
	let affectedLabelIndices = [];
	let getOptionsByType = [];
	let inRangeTrigger = [];

	if (connectedLabels) {
		triggerLabelIndex = labels.indexOf(connectedLabels[0]);
		for (let i = 1; i < connectedLabels.length; i++) {
			let index = labels.indexOf(connectedLabels[i]);
			if (index !== -1) {
				// Ensure the label exists
				affectedLabelIndices.push(index);
			}
		}
		affectedLabelIndices.forEach((index) => {
			// Ensure each function is retrieved properly from the options array
			getOptionsByType[index] = options[index];
		});
	}

	// Default styling and disabled states
	const defaultFlex = flex || new Array(labels.length).fill(1);
	const defaultDisabledFields = disabledFields.length ? disabledFields : new Array(labels.length).fill(false);
	const isValidInput = (input) => {
		// First, check if the input matches the format [number, number]
		const regex = /^\[(\d+),\s*(\d+)\]$/;
		const match = input.match(regex);

		if (match) {
			// Extract numbers from the regex match
			const num1 = parseInt(match[1], 10);
			const num2 = parseInt(match[2], 10);

			// Check that num1 is less than num2
			return num1 < num2;
		}

		// Return false if input doesn't match the regex
		return false;
	};
	
	const handleTriggerChange = (event, index, fieldName, validationFlag) => {

		if (validationFlag) {
			const newErrors = [...errors];
			newErrors[index] = !isValidInput(event.target.value);
			setErrors(newErrors);
			setValidationLabel(fieldName);
		}

		if (!options || !options[triggerLabelIndex]) {
			return; // Exit the function if options for 'key' are not valid
		}

		const selectedOption = options[triggerLabelIndex].find((opt) => opt.value === event.target.value);
		if (selectedOption) {
			const newType = selectedOption.type;

			setSelectedType(newType); // Update the state to reflect new type for dependent options
			setIndexOptions(index); // Update the index for the dependent options
		} else {
			console.log("No matching option found in the options array for the given value");
		}

		// Propagate changes to Formik state using the handleChange provided
		handleChange(event, index, fieldName);
	};

	return (
		<>
			{label && <label className={classes.labelWithLine}>{label}:</label>}
			{values &&
				values.map((value, index) => (
					<div key={index} className={classes.dynamicInputs}>
						{labels.map((label, labelIndex) => {
							let currentValue = typeof value === "object" ? value[label.toLowerCase()] || "" : value || "";
							const inputType = types?.[labelIndex] || "input";
							const isDropdown = inputType === "dropdown";
							const isDynamicInput = inputType === "dynamicInput";
							const isRequiredLabel =
								requiredLabels && requiredLabels.includes(label) ? requiredLabels.includes(label) : false;

							let inputOptions,
								selectedOption,
								inputProps,
								isTypeBool = false;

							if(label === "num_op" && currentValue === "in range"){
								inRangeTrigger[index] = true;
							}
							
							// Decide which options to use based on whether the label is the affected one
							if (connectedLabels && affectedLabelIndices.includes(labelIndex)) {
								selectedOption = options[triggerLabelIndex].find(
									(opt) => opt.value === value[connectedLabels[0].toLowerCase()],
								);
								const operatorsFunction = getOptionsByType[labelIndex];
								if (selectedOption == undefined) {
									inputOptions = operatorsFunction("none");
									inputProps = operatorsFunction("none");
								} else if (isDynamicInput) {
									indexOptions === index
										? (inputProps = operatorsFunction(selectedType, value))
										: (inputProps = operatorsFunction(selectedOption.type, value));

									currentValue = inputProps?.disabled === true ? (currentValue = "") : currentValue;

									isTypeBool = selectedOption.type === "bool";
								} else if (indexOptions === index) {
									inputOptions = operatorsFunction(selectedType, value) || [];
								} else {
									inputOptions = operatorsFunction(selectedOption.type, value) || [];
								}
							} else {
								inputOptions = options?.[labelIndex] || [];
							}

							if (complexLabel && complexLabel.includes(label)) {
								// Variables for storing options and results
								let allNewInputOptions = [];

								affectCounter.forEach((ac) => {
									// Call funcAddOptions and store the results
									const newInputOptions = funcAddOptions(
										inputOptions[ac], // This is used to adjust the counter based on the current length of the inputOptions array
										counter[ac], // Assuming 'counter' is an object with keys from affectCounter
										values,
										value,
										ac,
										complexLabel,
										label,
									);

									// Append the results to allNewInputOptions
									allNewInputOptions = allNewInputOptions.concat(newInputOptions);
								});

								// If needed, assign it to another variable or return it
								inputOptions = allNewInputOptions;

								inputOptions.push({
									value: "",
									label: "None",
								});
							} else if (affectCounter == label) {
								inputOptions = funcAddOptions(
									inputOptions.length,
									counter[affectCounter],
									values,
									value,
									affectCounter,
								); // Add an additional option with empty value and label so the user can change from the default
								inputOptions.push({
									value: "",
									label: "None",
								});
							}
							
							return (
								<TextField
									key={`${id}-${index}-${labelIndex}`}
									{...inputProps}
									select={isDropdown || (isDynamicInput && isTypeBool)}
									label={label}
									value={currentValue}
									onChange={(e) => {
										if (triggerLabelIndex === labelIndex) {
											// Check if this is the trigger label
											handleTriggerChange(e, index, label);
										} else if (validationFlag && inRangeTrigger[index] && label === "value") {
											handleTriggerChange(e, index, label, true);
										} else {
											handleChange(e, index, label); // Default handleChange for other fields
										}
									}}
									error={errors[index] && validationLabel === label}
									helperText={errors[index] && validationLabel === label ? "Format: [num1, num2]\nRule: num1 < num2" : ""}
									variant="filled"
									className={classes.dynamicInputs__input}
									style={{ flex: defaultFlex[labelIndex] }}
									disabled={defaultDisabledFields[labelIndex] || inputProps?.disabled}
									required={isRequiredLabel || inputProps?.required}
								>
									{isDropdown &&
										inputOptions.map((option, optionIndex) => (
											<MenuItem key={`${id}-${index}-${labelIndex}-${optionIndex}`} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									{isTypeBool && (
										<MenuItem key={`${id}-${index}-${labelIndex}-bool-true`} value={"true"}>
											True
										</MenuItem>
									)}
									{isTypeBool && (
										<MenuItem key={`${id}-${index}-${labelIndex}-bool-false`} value={"false"}>
											False
										</MenuItem>
									)}
								</TextField>
							);
						})}

						<Button onClick={() => onRemoveAction(index)} className={classes.removeButton} color="error">
							<RemoveIcon />
						</Button>
					</div>
				))}
			<Button
				onClick={onAddAction}
				className={classes.addButton}
				startIcon={<AddIcon className={classes.addIcon} />}
				color="primary"
			>
				Add {label}
			</Button>
		</>
	);
};

export default DynamicInputs;