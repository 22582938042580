import React, { memo } from "react";
import { Handle, Position, NodeResizeControl  } from "reactflow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNodeHandlers } from "./useNodeHandlers";
function ResizeIcon() {
	return (
	  <svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 24 24"
		strokeWidth="2"
		stroke="#ff0071"
		fill="none"
		strokeLinecap="round"
		strokeLinejoin="round"
		style={{ position: 'absolute', right: 5, bottom: 5 }}
	  >
		<path stroke="none" d="M0 0h24v24H0z" fill="none" />
		<polyline points="16 20 20 20 20 16" />
		<line x1="14" y1="14" x2="20" y2="20" />
		<polyline points="8 4 4 4 4 8" />
		<line x1="4" y1="4" x2="10" y2="10" />
	  </svg>
	);
  }
  const controlStyle = {
	background: 'transparent',
	border: 'none',
  };
  

const NodeComponent = ({ id, data, isConnectable }) => {
	const { handleConnect, onDeleteNode, extractMultipleTitlesByType, extractOptions } = useNodeHandlers();

	data.dependenciesOptions = extractMultipleTitlesByType(data.dependencies || []);
	data.complexDependenciesOptions = extractOptions(data.complexDependencies || {});
	
	return (
		<>
			{(!data?.group || data?.noInfo) &&
				<>
					<button onClick={() => onDeleteNode(id, data)} className="delete-button">
						<div className="delete gradient">
							<div>
								<DeleteIcon />
							</div>
						</div>
					</button>
					<button onClick={() => data.onOpenPopup(data)} className="edit-button">
						<div className="cloud gradient">
							<div>
								<EditIcon />
							</div>
						</div>
					</button>
				</>
			}
			<div className="wrapper gradient">
				<div className={`inner ${data?.group ? 'grouped' : ''}`}>
					<div className={`body`}>
						{data.icon && <div className="icon">{data.icon}</div>}
						<div>
							<div className="title">{data.title}</div>
							{data.subtitle && <div className="communication_protocols">{data.subtitle}</div>}
						</div>
					</div>
					{data?.group &&
						<NodeResizeControl style={controlStyle} minWidth={400} minHeight={300} >
							<ResizeIcon />
						</NodeResizeControl>
					}
				</div>
			</div>
			{data.handles &&
				data.handles.map((handle, index) => (
					<Handle
						key={`${handle.type}-${handle.position}-${index}`}
						type={handle.type}
						position={
							Position[handle.position ? handle.position : handle.type.toLowerCase() == "source" ? "Right" : "Left"]
						}
						id={`${handle.type}-${handle.parameterDependency ? handle.parameterDependency : data.type.toLowerCase()}`}
						style={handle.style}
						onConnect={(connection) =>
							handleConnect(connection, id, data, handle.connectionLimit, handle.handleTarget, handle.parameterDependency)
						}
					/>
				))}
		</>
	);
};

export default memo(NodeComponent);
