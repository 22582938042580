export const transformNewToOldJson = (newJson) => {
    const oldJson = {
        nodeTypes: {}
    };

    for (const [nodeType, config] of Object.entries(newJson.nodeTypes)) {
        const { interfaceConfig, grammar } = config;

        // Initialize oldJson node type structure
        oldJson.nodeTypes[nodeType] = {
            ...interfaceConfig,
            endKeyword: grammar.endKeyword,
            startPattern: grammar.startPattern,
            parameterException: grammar.parameterException,
            maxInstances: grammar.maxInstances,
            startPatternExceptions: grammar.startPatternExceptions,
            parameters: [],
        };

        // Collect names of nested parameters
        const nestedParamNames = new Set();
        if (grammar.nestedParameters && Array.isArray(grammar.nestedParameters)) {
            for (const nestedParam of grammar.nestedParameters) {
                if (nestedParam.parameters && Array.isArray(nestedParam.parameters)) {
                    nestedParam.parameters.forEach(p => nestedParamNames.add(p));
                }
            }
        }

        // Transform parameters
        if (grammar.parameters && Array.isArray(grammar.parameters)) {
            for (const param of grammar.parameters) {
                if (!nestedParamNames.has(param.name)) {
                    const correspondingInterfaceParam = interfaceConfig.parameters.find(p => p.name === param.name) || {};
                    oldJson.nodeTypes[nodeType].parameters.push({
                        ...param,
                        ...correspondingInterfaceParam,
                    });
                }
            }
        }

        // Transform nested parameters
        if (grammar.nestedParameters && Array.isArray(grammar.nestedParameters)) {
            oldJson.nodeTypes[nodeType].nestedParameters = {};
            for (const nestedParam of grammar.nestedParameters) {
                let nestedParamConfigs = [];
                let multiNested = false;

                if (nestedParam.parameters) {
                    for (const paramName of nestedParam.parameters) {
                        const grammarParam = grammar.parameters.find(p => p.name === paramName);
                        const interfaceParam = interfaceConfig.parameters.find(p => p.name === paramName);

                        if (grammarParam && interfaceParam) {
                            nestedParamConfigs.push({
                                ...grammarParam,
                                ...interfaceParam,
                            });
                            multiNested = true;
                        }
                    }
                }

                if (!multiNested) {
                    const nestedParamConfig = interfaceConfig.parameters.find(p => p.name === nestedParam.name);
                    if (nestedParamConfig) {
                        nestedParamConfigs.push({
                            ...nestedParam,
                            ...nestedParamConfig,
                        });
                    }
                }

                if (nestedParamConfigs.length > 0) {
                    oldJson.nodeTypes[nodeType].nestedParameters[nestedParam.name] = nestedParamConfigs;
                } else {
                    oldJson.nodeTypes[nodeType].nestedParameters[nestedParam.name] = [];
                }
            }

            if (Object.keys(oldJson.nodeTypes[nodeType].nestedParameters).length === 0) {
                delete oldJson.nodeTypes[nodeType].nestedParameters;
            }
        }

        // Add dependencies if present
        if (grammar.dependencies && Object.keys(grammar.dependencies).length > 0) {
            oldJson.nodeTypes[nodeType].dependencies = grammar.dependencies;
        }
    }

    return oldJson;
};
