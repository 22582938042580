const getInputByType = (type, currentValue) => {
	// Exception for "in range" operator
	if (currentValue && currentValue.num_op === "in range") {
		return { type: "text" };
	}

	switch (type) {
		case "str":
			return { type: "text" };
		case "float":
			return { type: "number", step: "0.01" };
		case "int":
			return { type: "number", step: "1" };
		case "bool":
			return {};
		case "list":
			return { type: "text" }; 
		case "dict":
			return { type: "text" }; 
		default:
			return { type: "text" };
	}
};

const normalizeToArray = (input) => {
	if (!Array.isArray(input)) {
		return [input];
	}
	return input;
};

// Function to merge inputProps
const mergeInputProps = (inputPropsArray) => {
	if (!inputPropsArray) {
		return {};
	}
	if (Array.isArray(inputPropsArray)) {
		return inputPropsArray.reduce((acc, inputProps) => {
		return { ...acc, ...inputProps };
		}, {});
	}
	return inputPropsArray;
};
	

function generateMarksArray(input) {
    // If the input is a range notation, convert it to an explicit array
    if (Array.isArray(input) && input.length === 3 && input[1] === '...') {
        const start = input[0];
        const end = input[2];
        input = Array.from({ length: end - start + 1 }, (_, i) => start + i);
    }
    
    // Map the array to the desired format
    return input.map(num => ({
        value: num,
        label: num.toString()
    }));
}

const functionReferences = {
	getInputByType,
	// Add other functions here if needed
};

function processNestedFieldOptions(nestedField, types) {
	if (!nestedField.options) {
		return [];
	}

	let optionsArrays = [];

	// Check if the first element is an array (nested array scenario)
	if (Array.isArray(nestedField.options[0])) {
		optionsArrays = nestedField.options;
	} else {
		// Handle flat array
		optionsArrays = [nestedField.options];
	}

	// Process each options array
	const processedOptionsArrays = optionsArrays.map((optionsArray) => {
		const logicalOperations = optionsArray.map((option) => ({
			value: option,
			label: option,
		}));
		return [...logicalOperations];
	});
	// Arrange options according to types array
	const finalOptions = types.map((type) => {
		if (type === "dropdown" && processedOptionsArrays.length > 0) {
			return processedOptionsArrays.shift();
		}
		return null;
	});

	return finalOptions;
}

const processComplexNestedFieldOptions = (nestedField, formData) => {
	const options = [];
	const types = [];
	const connectedLabels = nestedField.connectedLabels || [];

	nestedField.inputTypes.forEach((type, index) => {
		if (type === "dropdown") {
			let mergedOptions = [];
			if (formData.complexDependenciesOptions && nestedField.options && nestedField.options[index]) {
				const optionKeys = Array.isArray(nestedField.options[index])
					? nestedField.options[index]
					: [nestedField.options[index]];
				optionKeys.forEach((optionKey) => {
					if (formData.complexDependenciesOptions[optionKey]) {
						mergedOptions = mergedOptions.concat(formData.complexDependenciesOptions[optionKey]);
					}
				});
				options.push(mergedOptions);
			} else {
				options.push([]);
			}
			types.push("dropdown");
		} else {
			const option = nestedField.options && nestedField.options[index] ? nestedField.options[index] : null;
			if (typeof option === "string" && functionReferences[option]) {
				options.push(functionReferences[option]);
			} else {
				options.push(option);
			}
			types.push(type);
		}
	});
	return {
		options,
		types,
		connectedLabels,
	};
};

const getComplexDependenciesOptions = (nestedField, formData) => {
	const complexDependenciesOptions = [];

	if (!nestedField.options) {
		console.warn("nestedField.options is undefined");
		return complexDependenciesOptions;
	}

	// Process each option key
	const optionKeys = Array.isArray(nestedField.options) ? nestedField.options : [nestedField.options];
	optionKeys.forEach((optionKey) => {
		let mergedOptions = [];
		if (typeof optionKey === "string" && formData.complexDependenciesOptions[optionKey]) {
			mergedOptions = formData.complexDependenciesOptions[optionKey].map((opt) => opt.value);
		}
		complexDependenciesOptions.push(mergedOptions);
	});

	// Flatten the array of arrays into a single array
	const flattenedOptions = complexDependenciesOptions.flat();

	return flattenedOptions;
};

const onFormContent = (formData = {}, dslJson) => {
	if (dslJson == null) return [];
	const nodeType = dslJson.nodeTypes[formData.type];
	if (!nodeType) return [];
	const parameters = [];
	// console.log("formData: ", formData);
	// console.log("nodeType: ", nodeType);

	// Add parameters to the form
	if (nodeType.parameters) {
		nodeType.parameters.forEach((parameter, index) => {
			const fieldConfig = {
				key: `parameter-${parameter.name}-${index}`,
				label: parameter.label || parameter.name.charAt(0).toUpperCase() + parameter.name.slice(1),
				customType: parameter.inputType || "wideInput",
				id: parameter.name,
				type: parameter.type || "text",
				multiline: parameter.multiLine || false,
				inputProps: mergeInputProps(parameter.inputProps) || {},
				multiple: parameter.multiple || false,
				placeholder: parameter.placeholder || parameter.label || parameter.name.charAt(0).toUpperCase() + parameter.name.slice(1),
				value: formData[parameter.name] === true ? "true" : formData[parameter.name] === false ? "false" : formData[parameter.name]
			};

			if (parameter.inputType === "slider") {
				fieldConfig.defaultValue = formData[parameter.name];
				fieldConfig.track = parameter.track || "normal";
				fieldConfig.marks = generateMarksArray(parameter.marks);
				fieldConfig.min = parameter.min ? parameter.min : parameter.marks[0];
				fieldConfig.max = parameter.max ? parameter.max : parameter.marks[parameter.marks.length - 1];
				
			}
			if (parameter.inputType === "switch") {
				fieldConfig.defaultValue = formData[parameter.name];
			}

			if (parameter.inputType === "autocomplete") {
				if (parameter.options) {
					fieldConfig.options = parameter.options.filter((option) => option !== "");
					if (parameter?.complexDependency) {
						parameter.options = parameter?.complexDependency;
						const options = getComplexDependenciesOptions(parameter, formData);
						fieldConfig.options = options;
					}
					fieldConfig.options.push("");
				} else {
					const dependencies = normalizeToArray(parameter.dependency);
					const dependenciesOptions = formData.dependenciesOptions
						.filter((optionGroup) => {
							const include = dependencies.includes(optionGroup.type);
							return include;
						})
						.reduce((acc, optionGroup) => {
							return acc.concat(optionGroup.titles);
						}, []);
					fieldConfig.options = dependenciesOptions.filter((option) => option !== "");
					

					fieldConfig.options.push("");
				}
			}

			if (parameter.visibleCondition) {
				fieldConfig.visible_condition = parameter.visibleCondition;
			}

			parameters.push(fieldConfig);

			// Handle helperType
			if (parameter.helperType && Array.isArray(parameter.helperType)) {
				const helperLabel = `Helper Chips of ${parameter.helperType.join(", ")}`;
				let options = [];

				parameter.helperType.forEach((helper, idx) => {
					if (formData.complexDependenciesOptions && formData.complexDependenciesOptions[helper]) {
						options = options.concat(formData.complexDependenciesOptions[helper]);
					}
				});

				const helperFieldConfig = {
					key: `helper-${parameter.name}-${index}`,
					label: helperLabel,
					id: `helper-${parameter.name}-${index}`,
					customType: "optionsfield",
					options: options, // Already flattened
				};

				parameters.push(helperFieldConfig);
			}
		});
	}

	// Add nested parameters to the form if nodeType.nestedParameters exists
	if (nodeType.nestedParameters) {
		Object.keys(nodeType.nestedParameters).forEach((nestedFieldName, nestedIndex) => {
			const nestedFieldArray = formData[nestedFieldName] || [];

			nodeType.nestedParameters[nestedFieldName].forEach((nestedField, index) => {
				const multipleNestedFields = nodeType.nestedParameters[nestedFieldName].length > 1 ;
				const multipleNestedFieldsName = multipleNestedFields ? nestedField.name : nestedFieldName;
				const nestedFieldsInputType =  nestedField.inputType || "wideInput";
				const nestedFieldConfig = {
					key: `nestedField-${nestedField.label||multipleNestedFieldsName}-${index}-${nestedIndex}`,
					label: nestedField.label || multipleNestedFieldsName.charAt(0).toUpperCase() + multipleNestedFieldsName.slice(1),
					customType: nestedFieldsInputType,
					id: multipleNestedFieldsName,
					type: nestedField.type || "text",
					inputProps: mergeInputProps(nestedField.inputProps) || {},
					multiline: nestedField.multiLine || false,
					multiple: nestedField.multiple || false,
					placeholder: nestedField.placeholder || nestedField.label || multipleNestedFieldsName.charAt(0).toUpperCase() + nestedFieldName.slice(1),
				};

				if (nestedFieldsInputType === "wideInput") {
					
					const value = nestedFieldArray.find((item) => item[multipleNestedFieldsName]);
					nestedFieldConfig.value = value ? value[multipleNestedFieldsName] : "";
				} else {
					nestedFieldConfig.value = nestedFieldArray;
				}

				if (nestedFieldsInputType === "dynamicInputs") {
					nestedFieldConfig.value = nestedFieldArray;
					if (nestedField.types) {
						nestedFieldConfig.types = nestedField.types;
					}
					if (nestedField.options && nestedField.types) {
						const processedOptions = processNestedFieldOptions(nestedField, nestedField.types);
						nestedFieldConfig.options = processedOptions;
					}
					if (nestedField.options && nestedField?.inputTypes) {
						const { options, types, connectedLabels } = processComplexNestedFieldOptions(nestedField, formData);
						nestedFieldConfig.options = options;
						nestedFieldConfig.types = types;
						if (connectedLabels.length > 0) {
							nestedFieldConfig.connectedLabels = connectedLabels;
						}
					}
					nestedFieldConfig.labels = nestedField.parameters;
				}

				if (nestedFieldsInputType === "autocomplete") {
					const dependencies = normalizeToArray(nestedField.dependency);
					const dependenciesOptions = formData.dependenciesOptions
					.filter((optionGroup) => {
						const include = dependencies.includes(optionGroup.type);
						return include;
					})
					.reduce((acc, optionGroup) => {
						return acc.concat(optionGroup.titles);
					}, []);
					const value = nestedFieldArray;
					nestedFieldConfig.value = value;
					nestedFieldConfig.options = dependenciesOptions.filter((option) => option !== "");
					if (!nestedFieldConfig.options.includes("")) {
						nestedFieldConfig.options.push("");
					}
					if (nestedField?.options) {
						const options = getComplexDependenciesOptions(nestedField, formData);
						nestedFieldConfig.options = options;
					}
				}

				if (nestedField.visibleCondition) {
					nestedFieldConfig.visible_condition = nestedField.visibleCondition;
				}

				parameters.push(nestedFieldConfig);
			});
		});
	}

	// Add hidden parameters
	parameters.push(
		{
			key: "hidden-uniqueId",
			customType: "hidden",
			id: "uniqueId",
			value: formData.uniqueId,
		},
		{
			key: "hidden-type",
			customType: "hidden",
			id: "type",
			value: formData.type,
		},
	);

	// Add submit button
	parameters.push({
		key: "submit-button",
		customType: "button",
		id: "submit",
		type: "submit",
		text: "Save Changes",
	});

	// console.log("parameters: ", parameters);

	return parameters;
};
export { onFormContent };
