import { memo, useCallback, useEffect, useState } from "react";

import { Grid, Typography, Avatar } from "@mui/material";
import Chip from '@mui/material/Chip';

import DeleteIcon from '@mui/icons-material/Delete';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import KeyIcon from '@mui/icons-material/Key';
import EditIcon from '@mui/icons-material/Edit';

import Popup from "../components/Popup.js";
import Spinner from "../components/Spinner.js";
import Form from "../components/Form.js";
import Dialog from "../components/Dialog.js";
import { useSnackbar, jwt } from "../utils/index.js";

import { makeStyles } from '@mui/styles';

import {
	getSecretsGroups,
	newSecretsGroup,
	editSecretsGroup,
	deleteSecretsGroup,
	newSecret,
	editSecret,
	deleteSecret,
	getSecrets,
} from "../api/index.js";

const useStyles = makeStyles({
	buttonCursor: {
		cursor: "pointer",
	},
	select: {
		color: "#F1A542", // Set the desired color for the select
		"&:before": {
			borderColor: "#F1A542", // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: "#F1A542", // Set the desired color for the select's border when focused
		},
	},
});

const Secrets = () => {
	const { success, error } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [user, setUser] = useState(jwt.decode());
	const [secretsGroups, setSecretsGroups] = useState([]);
	const [secrets, setSecrets] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState(null);
	const [newSecretsGroupPopupOpen, setNewSecretsGroupPopupOpen] = useState(false);
	const [newSecretPopupOpen, setNewSecretPopupOpen] = useState(false);
	const [groupToEdit, setGroupToEdit] = useState(null);
	const [secretToEdit, setSecretToEdit] = useState(null);
	const [secretToDelete, setSecretToDelete] = useState(null);
	const [groupToDelete, setGroupToDelete] = useState(null);

	const classes = useStyles();

	const fetchGroupSecrets = async (groupId) => {
		setIsLoading(true);

		try {
			const { success: successCode, secrets: scrts } = await getSecrets(groupId);
			console.log("Secrets:", scrts);
			if (successCode) {
				setSecrets(scrts);
			} else {
				error();
			}
		} catch { /* empty */ }

		setIsLoading(false);
	};

	const fetchData = useCallback(async () => {
		setIsLoading(true);

		// Fetch the user's secrets groups
		const { success: scsGroups, secretsGroups: secrGroups } = await getSecretsGroups();
		console.log("Secrets groups", secrGroups);
		if (scsGroups) {
			setSecretsGroups(secrGroups);
		} else {
			error();
		}

		if (selectedGroup !== null) {
			await fetchGroupSecrets(selectedGroup._id);
		}

		setIsLoading(false);
	}, [selectedGroup]);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	const checkSecretsGroupUnique = (groupName) => {
		if (groupToEdit && groupToEdit.title === groupName) {
			return true;
		}

		return secretsGroups.filter((e) => e.title === groupName).length === 0;
	};

	const checkSecretsNameUnique = (secretsName) => {
		if (secretToEdit && secretToEdit.name === secretsName) {
			return true;
		}

		return secrets.filter((e) => e.name === secretsName).length === 0;
	};

	const handleSecretsGroupCreation = async (values) => {
		try {
			const { success: successCode, message } = await newSecretsGroup(
				values.secretsName,
				values.description,
			);
			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch {
			error();
		}
	};

	const handleSecretsGroupEdit = async (values) => {
		try {
			const { success: successCode, message } = await editSecretsGroup(
				groupToEdit._id,
				values.secretsName,
				values.description,
			);
			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch {
			error();
		}
	};

	const newSecretsGroupSubmitHandler = async (values) => {
		if (!checkSecretsGroupUnique(values.secretsName)) {
			error("Group name must be unique for each user.");
			return;
		}

		setIsLoading(true);
		try {
			await (groupToEdit ? handleSecretsGroupEdit(values) : handleSecretsGroupCreation(values));

			setNewSecretsGroupPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const handleSecretCreation = async (values) => {
		try {
			const { success: successCode, message } = await newSecret(
				selectedGroup._id,
				values.secretsName,
				values.description,
				values.secretsValue,
			);
			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch {
			error();
		}
	};

	const handleSecretEdit = async (values) => {
		try {
			const { success: successCode, message } = await editSecret(
				secretToEdit._id,
				values.secretsName,
				values.description,
				values.secretsValue,
			);
			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch {
			error();
		}
	};

	const newSecretSubmitHandler = async (values) => {
		if (!checkSecretsNameUnique(values.secretsName)) {
			error("Secret name must be unique for each group.");
			return;
		}

		setIsLoading(true);

		try {
			await (secretToEdit ? handleSecretEdit(values) : handleSecretCreation(values));

			setNewSecretPopupOpen(false);
		} catch {
			error();
		}

		await fetchData();
		setIsLoading(false);
	};

	const deleteSecretsGroupSubmitHandler = async (secretsGroupId) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await deleteSecretsGroup(secretsGroupId);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const deleteSecretSubmitHandler = async (secretId) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await deleteSecret(secretId);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch {
			error();
		}

		await fetchData();
		setIsLoading(false);
	};

	const newSecretsGroupFormContent = [
		{
			customType: "wideInput",
			id: "secretsName",
			type: "input",
			multiline: false,
			width: 600,
			placeholder: "Title",
			value: groupToEdit?.title,
		},
		{
			customType: "wideInput",
			id: "description",
			type: "input",
			multiline: false,
			width: 600,
			placeholder: "Description",
			value: groupToEdit?.description,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Submit",
		},
	];

	const newSecretFormContent = [
		{
			customType: "wideInput",
			id: "secretsName",
			type: "input",
			multiline: false,
			width: 600,
			placeholder: "Name",
			value: secretToEdit?.name,
		},
		{
			customType: "wideInput",
			id: "description",
			type: "input",
			multiline: false,
			width: 600,
			placeholder: "Description",
			value: secretToEdit?.description,
		},
		{
			customType: "wideInput",
			id: "secretsValue",
			type: "input",
			multiline: false,
			width: 600,
			placeholder: "Value",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Submit",
		},
	];

	return (
		<>
			<Spinner open={isLoading} />
			<Popup
				width="800px"
				open={newSecretsGroupPopupOpen}
				title={`${groupToEdit ? "Edit" : "Create new"} secrets group ${groupToEdit ? `: ${groupToEdit.title}` : ""}`}
				onClose={() => {
					setNewSecretsGroupPopupOpen(false);
				}}
			>
				<Form
					content={newSecretsGroupFormContent}
					validationSchema="secretsGroupSchema"
					onSubmit={newSecretsGroupSubmitHandler}
				/>
			</Popup>
			<Popup
				width="800px"
				open={newSecretPopupOpen}
				title={`${secretToEdit ? "Edit" : "Create new"} secret ${secretToEdit ? `: ${secretToEdit.name}` : ""}`}
				onClose={() => {
					setNewSecretPopupOpen(false);
				}}
			>
				<Form
					content={newSecretFormContent}
					validationSchema="secretsSchema"
					onSubmit={newSecretSubmitHandler}
				/>
			</Popup>
			<Dialog
				open={!!secretToDelete}
				title="Delete secret?"
				text={`Are you sure you want to delete secret "${secretToDelete?.name}"?`}
				confirmButton="Delete"
				cancelButton="Cancel"
				onConfirm={() => {
					setSecretToDelete(null);
					deleteSecretSubmitHandler(secretToDelete._id);
				}}
				onClose={() => {
					setSecretToDelete(null);
					error("Secret deletion aborted");
				}}
			/>
			<Dialog
				open={!!groupToDelete}
				title="Delete secret group?"
				text={`Are you sure you want to delete secret group "${groupToDelete?.title}"? All of its secrets will be deleted as well.`}
				confirmButton="Delete"
				cancelButton="Cancel"
				onConfirm={() => {
					setGroupToDelete(null);
					deleteSecretsGroupSubmitHandler(groupToDelete._id);
				}}
				onClose={() => {
					setGroupToDelete(null);
					error("Group deletion aborted");
				}}
			/>
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="top"
				justifyContent="flex-start"
			>

				<Grid
					container
					flexDirection="row"
					justifyContent="space-between"
				>
					<Grid
						container
						item
						width="100%"
						xs={5.8}
						justifyContent="flex-start"
						alignContent="flex-start"
					>
						<Grid
							container
							item
							width="100%"
							mb={2}
							display="flex"
							minHeight="60px"
							borderRadius="20px"
							alignItems="flex-start"
							justifyContent="flex-start"
						>
							<Grid
								container
								item
								width="100%"
								pl={2}
								xs={12}
								display="flex"
								minHeight="50px"
								maxHeight="50px"
								alignItems="center"
								sx={{
									borderBottom: 0.1,
									borderColor: "secondary.main",
								}}
							>
								<Grid
									item
									xs={9}
								>
									<Typography variant="h6" color="#ffffff">
										{"Secrets groups"}
									</Typography>
								</Grid>
								<Grid
									item
									xs={3}
									display="flex"
									flexDirection="row"
									alignItems="center"
									justifyContent="flex-end"
									pr={1}
								>
									<Chip
										key={-1}
										label="+"
										color="success"
										sx={{
											mr: 0.5,
										}}
										onClick={() => {
											setGroupToEdit(null);
											setNewSecretsGroupPopupOpen(true);
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Timeline
							sx={{
								[`& .${timelineItemClasses.root}:before`]: {
									flex: 0,
									padding: 0,
								},
							}}
							xs={7}
						>
							{
								secretsGroups?.length === 0 && (
									<Typography color="gray" pl={2} pb={1} pt={1} fontSize="0.8rem">
										{"No secrets groups yet. Create one!"}
									</Typography>
								)
							}
							{secretsGroups?.map((e, ind) => (
								<TimelineItem key={ind}>
									<TimelineSeparator>
										<TimelineDot
											color="white"
											sx={{
												boxShadow: "2px 2px 14px #262835",
											}}
										>
											<WorkspacesIcon style={{ color: "gray" }} />
										</TimelineDot>
										{ind !== secretsGroups.length - 1 && <TimelineConnector />}
									</TimelineSeparator>
									<TimelineContent>
										<Grid
											container
											display="flex"
											flexDirection="row"
										>
											<Grid
												item
												xs={10}
												display="flex"
												flexDirection="column"
											>
												<Typography fontSize="1.1rem" color={selectedGroup?._id === e._id ? "orange" : "#ffffff"}>
													{e.title}
												</Typography>
												<Typography
													color={selectedGroup?._id === e._id ? "orange" : (e.description ? "#ffffff" : "gray")}
													fontSize="0.8rem"
													mb={0.5}
												>
													{e.description || "No description."}
												</Typography>
											</Grid>
											<Grid
												item
												xs={2}
												display="flex"
												flexDirection="row"
												alignItems="center"
												justifyContent="flex-end"
											>
												<Avatar
													sx={{
														bgcolor: "secondary.main",
														width: 38,
														height: 38,
														mr: 0.5,
													}}
													className={classes.buttonCursor}
													onClick={() => {
														setSelectedGroup(e);
														setSecretToEdit(null);
														setGroupToEdit(null);
														fetchGroupSecrets(e._id);
													}}
												>
													<VisibilityIcon />
												</Avatar>
												<Avatar
													sx={{
														bgcolor: "secondary.main",
														width: 38,
														height: 38,
														mr: 0.5,
													}}
													className={classes.buttonCursor}
													onClick={() => {
														setGroupToEdit(e);
														setNewSecretsGroupPopupOpen(true);
													}}
												>
													<EditIcon />
												</Avatar>
												<Avatar
													sx={{
														bgcolor: "red",
														width: 38,
														height: 38,
													}}
													className={classes.buttonCursor}
													onClick={() => {
														setGroupToDelete(e);
													}}
												>
													<DeleteIcon />
												</Avatar>
											</Grid>
										</Grid>
									</TimelineContent>
								</TimelineItem>
							))}
						</Timeline>
					</Grid>
					{selectedGroup !== null && (
						<Grid
							container
							item
							width="100%"
							xs={5.8}
							justifyContent="flex-start"
							alignContent="flex-start"
						>
							<Grid
								container
								item
								width="100%"
								mb={2}
								display="flex"
								minHeight="60px"
								borderRadius="20px"
								alignItems="flex-start"
								justifyContent="flex-start"
							>
								<Grid
									container
									item
									width="100%"
									pl={2}
									xs={12}
									display="flex"
									minHeight="50px"
									maxHeight="50px"
									alignItems="center"
									sx={{
										borderBottom: 0.1,
										borderColor: "secondary.main",
									}}
								>
									<Grid
										item
										xs={9}
									>
										<Typography variant="h6" color="#ffffff">
											{`Secrets of "${selectedGroup.title}" group`}
										</Typography>
									</Grid>
									<Grid
										item
										xs={3}
										display="flex"
										flexDirection="row"
										alignItems="center"
										justifyContent="flex-end"
										pr={1}
									>
										<Chip
											key={-1}
											label="+"
											color="success"
											sx={{
												mr: 0.5,
											}}
											onClick={() => setNewSecretPopupOpen(true)}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Timeline
								sx={{
									[`& .${timelineItemClasses.root}:before`]: {
										flex: 0,
										padding: 0,
									},
								}}
								xs={7}
							>
								{
									secrets?.length === 0 && (
										<Typography color="gray" pl={2} pb={1} pt={1} fontSize="0.8rem">
											{"No secrets found."}
										</Typography>
									)
								}
								{secrets?.map((e, ind) => (
									<TimelineItem key={ind}>
										<TimelineSeparator>
											<TimelineDot
												color="white"
												sx={{
													boxShadow: "2px 2px 14px #262835",
												}}
											>
												<KeyIcon style={{ color: "gray" }} />
											</TimelineDot>
											{ind !== secrets.length - 1 && <TimelineConnector />}
										</TimelineSeparator>
										<TimelineContent>
											<Grid
												container
												display="flex"
												flexDirection="row"
											>
												<Grid
													item
													xs={10}
													display="flex"
													flexDirection="column"
												>
													<Typography fontSize="1.1rem" color="#ffffff">
														{e.name}
													</Typography>
													<Typography color={e.description ? "#ffffff" : "gray"} fontSize="0.8rem" mb={0.5}>
														{e.description || "No description."}
													</Typography>
												</Grid>
												<Grid
													item
													xs={2}
													display="flex"
													flexDirection="row"
													alignItems="center"
													justifyContent="flex-end"
												>
													<Avatar
														sx={{
															bgcolor: "secondary.main",
															width: 38,
															height: 38,
															mr: 0.5,
														}}
														className={classes.buttonCursor}
														onClick={() => {
															setSecretToEdit(e);
															setNewSecretPopupOpen(true);
														}}
													>
														<EditIcon />
													</Avatar>
													<Avatar
														sx={{
															bgcolor: "red",
															width: 38,
															height: 38,
														}}
														className={classes.buttonCursor}
														onClick={() => {
															setSecretToDelete(e);
														}}
													>
														<DeleteIcon />
													</Avatar>
												</Grid>
											</Grid>
										</TimelineContent>
									</TimelineItem>
								))}
							</Timeline>
						</Grid>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default memo(Secrets);
