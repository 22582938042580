import { transcriptToObjectsParsing } from "./transformer_dsl_obj";
import { iconComponents } from './utils';

const deepClone = (obj) => JSON.parse(JSON.stringify(obj));


const normalizeToArray = (input) => {
	if (!Array.isArray(input)) {
		return [input];
	}
	return input;
};

const IconComponent = ({ iconIdentifier }) => {
	if (iconIdentifier === null) return null;
    const Icon = iconComponents[iconIdentifier] || iconComponents.default;
    return <Icon />;
};

const hasFieldStartNull = (nestedParameters, nestedFieldKey) => {
    // Check if the nestedFieldKey exists in the nestedParameters object
    if (nestedParameters.hasOwnProperty(nestedFieldKey)) {
        const parameters = nestedParameters[nestedFieldKey];
        // Use the 'some' method to check if any parameter has parameterStart set to null
        return parameters.some((parameter) => parameter.parameterStart === null);
    }
    return false;
};


const formatParameter = (parameter, value) => {
    if (parameter.unquoteOuter && parameter.unquoteOuter  === true) {
        // Remove the outermost quotes if they exist
        if (typeof value === 'string' && value.startsWith('"') && value.endsWith('"')) {
            return value.slice(1, -1);
        }
        return value;
    } else {
        // Default behavior: return the value wrapped in quotes using JSON.stringify
        return value;
    }
};

const generateConfig = (nodeType, node, dslJson, lastNodeResponse = "") => {
	const nodeTypeConfig = dslJson.nodeTypes[nodeType];
	const configLines = [];
	const nestedNodesInNestedField = [];
	

	let startPatterns = normalizeToArray(nodeTypeConfig.startPattern);
	let startPatternExceptions = startPatterns;
	let startPattern = startPatterns[0];
	let exceptionMatched = false;

	
	if (nodeTypeConfig.startPatternExceptions) {
		const exceptions = normalizeToArray(nodeTypeConfig.startPatternExceptions);
	
		// Check for matching exception
		for (const exception of exceptions) {
			const exceptionField = Object.keys(exception)[0];
			const exceptionValue = exception[exceptionField];
			const exceptionOrder = exception.order || 1;
	
			if (node[exceptionField] && node[exceptionField] === exceptionValue) {
				startPatterns = startPatterns.map(pattern => pattern.replace(`\${${exceptionField}}`, exceptionValue));
				startPattern = startPatterns[exceptionOrder - 1];
				startPatternExceptions = startPatternExceptions[exceptionOrder - 1];
				exceptionMatched = true;
				break;
			}
		}
	
		if (!exceptionMatched) {
			// Filter out patterns that are targeted by any exceptions' order
			const exceptionOrders = exceptions.map(exception => exception.order - 1);
			const validStartPatterns = startPatterns.filter((pattern, index) => !exceptionOrders.includes(index));
	
			// Select the first valid pattern that matches or the first non-exception pattern
			startPattern = validStartPatterns.find(pattern => {
				const regex = new RegExp(pattern.replace(/\$\{(\w+)\}/g, "(\\w+)"));
				return regex.test(nodeTypeConfig.startPattern);
			}) || validStartPatterns[0];
		}
	}

	// Generate the start line by replacing placeholders with actual node values
	const startLine = startPattern.replace(/\$\{(\w+)\}/g, (_, p1) => node[p1]);
	configLines.push(startLine);
	
	// Extract the parameters included in the start pattern
	if(exceptionMatched) {
		startPattern = startPatternExceptions;
	}

	// Function to check visible conditions
	const checkVisibleCondition = (condition = { key: "", value: "" }, data) => {
		return Object.entries(condition).every(([key, value]) => {
			if (Array.isArray(value)) {
				// If value is an array, check if data[key] is included in the array
				return value.includes(data[key]);
			} else {
				// If value is a string, check for direct equality
				return data[key] === value;
			}
		});
	};

	// Generate field lines for the parameters not included in the start pattern
	if (nodeTypeConfig.parameters)
	nodeTypeConfig.parameters.forEach((parameter) => {
		if (node[parameter.name] !== undefined  && !startPattern.includes(`\${${parameter.name}}`)) {
			const isVisibleCondition = checkVisibleCondition(parameter?.visibleCondition, node);
			if (isVisibleCondition || !parameter?.visibleCondition) {
				if (parameter.optionsDelimiter && parameter.startDelimiter && parameter.endDelimiter && parameter.type === "array") {
					const values = normalizeToArray(node[parameter.name]).join(parameter.optionsDelimiter);
					const fieldLine = `    ${parameter.prefix}${parameter.startDelimiter}${values}${parameter.endDelimiter}`;
					configLines.push(fieldLine);
				} else {
					const fieldLine = `    ${parameter.prefix}${parameter.unQuoted ? node[parameter.name] : JSON.stringify(node[parameter.name])}`;
					configLines.push(fieldLine);
				}
			}
		}
	});


	const handleNestedFields = (nestedParameters, configLines, nestedNodesInNestedField, prefix = "    ") => {
		for (const nestedFieldKey in nestedParameters) {
			let nestedFieldArray = node[nestedFieldKey] || [];
			const isFieldStartNull = hasFieldStartNull(nestedParameters, nestedFieldKey);
			const preSpace = isFieldStartNull ? "    " : "        ";
			
			if (nestedParameters[nestedFieldKey].length > 1) {
				Object.keys(node).forEach((parameter) => {
					// Check if the parameter exists in nestedParameters under the dynamic nestedFieldKey
					if (nestedParameters[nestedFieldKey].some((nestedParam) => nestedParam.name === parameter)) {
						// Find the index of the parameter in the nestedFieldArray
						const existingIndex = nestedFieldArray.findIndex(item => item.hasOwnProperty(parameter));
						
						if (existingIndex !== -1) {
							// Replace the existing entry
							nestedFieldArray[existingIndex] = { [parameter]: node[parameter] };
						} else {
							// Add the entry if it doesn't exist
							nestedFieldArray.push({ [parameter]: node[parameter] });
						}
					}
				});
			}
			
			if (nestedFieldArray.length > 0) {
				nestedFieldArray = normalizeToArray(nestedFieldArray);
				const nestedFieldStart = `${prefix}${nestedFieldKey}:`;
				if (!isFieldStartNull) configLines.push(nestedFieldStart);
				// console.log("nestedFieldArray: ", nestedFieldArray);
				nestedFieldArray.forEach((nestedFieldObject, nestedFieldIndex) => {
					const nestedFieldConfig = nestedParameters[nestedFieldKey];
					nestedFieldConfig.forEach((nestedField) => {
						if (nestedField.nestedNodeTypes && nestedField.nestedNodeTypes.length > 0) {
							// Add the information about the nested node to the nestedNodesInNestedField array
							nestedNodesInNestedField.push(nestedFieldObject);
							return;
						}
						if (Array.isArray(nestedFieldObject[nestedField?.name])) {
							nestedFieldObject[nestedField.name].forEach((value) => {
								const nestedLine = `${nestedField.prefix}${value}`;
								configLines.push(`${preSpace}${nestedLine}`);
							});
						}
						if (nestedFieldObject[nestedField.name] || nestedFieldObject[nestedField.parameters?.[0]]) {
							if (nestedField.delimiter && nestedField.endDelimiter) {
								const nestedLine = `${nestedField.prefix}${nestedFieldObject[nestedField.parameters[0]]}${nestedField.delimiter == "" ? "    " : nestedField.delimiter}${nestedFieldObject[nestedField.parameters[1]]}${nestedField.endDelimiter}`;
								configLines.push(`${preSpace}${nestedLine}`);
							} else if (nestedField.endDelimiter && nestedField.parameters.length === 1) {
								const endDelimiter = nestedFieldArray.length === nestedFieldIndex + 1 ? "" : nestedField.endDelimiter;
								const nestedLine = `${nestedField.prefix}${nestedFieldObject[nestedField.parameters[0]]}${endDelimiter}`;
								configLines.push(`${preSpace}${nestedLine}`);
							} else if (nestedField.delimiter || nestedField.delimiter == "") {
								const nestedLine = `${nestedField.prefix}${nestedFieldObject[nestedField.parameters[0]]}${nestedField.delimiter == "" ? "    " : nestedField.delimiter}${nestedFieldObject[nestedField.parameters[1]]}`;
								configLines.push(`${preSpace}${nestedLine}`);
							} else {
								const nestedLine = `${nestedField.prefix}"${nestedFieldObject[nestedField.name] || nestedFieldObject[nestedField.parameters[0]]}"`;
								configLines.push(`${preSpace}${formatParameter(nestedField, nestedLine)}`);
							}
						} else if (typeof nestedFieldObject === "string") {
							const nestedLine = `${nestedField.prefix}${nestedFieldObject}`;
							configLines.push(`${preSpace}${nestedLine}`);
						}
					});
				});
			}
		}
	};

	handleNestedFields(nodeTypeConfig.nestedParameters, configLines, nestedNodesInNestedField );

	// Handle nestedNodeTypes recursively within the parent node
	if (nodeTypeConfig.nestedNodeTypes && nodeTypeConfig.nestedNodeTypes.length > 0) {
		node.nestedNodes.forEach((nestedNode) => {
			const nestedNodeType = nestedNode.type;
			let extraPrefix = "";
	
			// Determine the key dynamically from the first object in nestedNodesInNestedField
			const dynamicKey = nestedNodesInNestedField.length > 0 ? Object.keys(nestedNodesInNestedField[0])[0] : null;
	
			// Find the last node in the nestedNodesInNestedField to determine the end keyword
			if (Array.isArray(nestedNodesInNestedField) && nestedNodesInNestedField.length > 0) {
				lastNodeResponse = nestedNodesInNestedField[nestedNodesInNestedField.length - 1][dynamicKey];
			}
	
			// Check if nestedNodesInNestedField is an array and matches nestedNode title
			nestedNodesInNestedField.forEach((obj) => {
				if (obj[dynamicKey] === nestedNode.title) {
					extraPrefix = "    ";
				}
			});
	
			// Add extra prefix to the comment line for the nested node type
			configLines.push(`${extraPrefix}    // ${dslJson.nodeTypes[nestedNodeType].title} ------->`);
	
			// Generate config with or without extra prefix
			const nestedConfig = generateConfig(nestedNodeType, nestedNode, dslJson, lastNodeResponse);
			const nestedConfigLines = nestedConfig.split("\n").map(line => `${extraPrefix}    ${line}`);
			configLines.push(...nestedConfigLines);
		});
	}

	const endKeywords = normalizeToArray(nodeTypeConfig.endKeyword);
	const lastKeywordIndex = nodeTypeConfig.lastKeywordIndex || 0;  

	// If this is the last node in the nestedNodesInNestedField, use the lastKeywordIndex; otherwise, use the alternative end keyword.
	const endKeyword = node.title && node.title === lastNodeResponse
		? endKeywords[lastKeywordIndex]
		: endKeywords.find((_, idx) => idx !== lastKeywordIndex) || endKeywords[0];


	configLines.push(endKeyword);
	return configLines.join("\n");
};

const replaceNestedNodes = (entity, parsedData, dslJson) => {
    const nodeTypeConfig = dslJson.nodeTypes[entity.type];
    
    if (!nodeTypeConfig || !nodeTypeConfig.nestedNodeTypes) {
        return entity; // No nested nodes to replace
    }

    // Initialize nestedNodes if not present
    if (!entity.nestedNodes) {
        entity.nestedNodes = [];
    }

    // Replace or update nested nodes
    nodeTypeConfig.nestedNodeTypes.forEach(nestedType => {
		// Try to find updated nested nodes by entity.title first
		let updatedNestedNodes = parsedData[nestedType]?.filter(
			updatedNode => updatedNode.parent === entity.title
		);

		// If not found by title, try using entity.uniqueId
		if ((!updatedNestedNodes || updatedNestedNodes.length === 0) && entity.uniqueId) {
			updatedNestedNodes = parsedData[nestedType]?.filter(
				updatedNode => updatedNode.parent === entity.uniqueId
			);
		}

        if (updatedNestedNodes && updatedNestedNodes.length > 0) {
            // Clear existing nestedNodes of this type
            entity.nestedNodes = entity.nestedNodes.filter(
                nestedNode => nestedNode.type !== nestedType
            );

            // Recursively update each nested node with its latest version
            updatedNestedNodes.forEach(updatedNode => {
                entity.nestedNodes.push(replaceNestedNodes(updatedNode, parsedData, dslJson));
            });
			console.log(" ", deepClone(entity));
        }
    });

    return entity;
};


const updateModelWithFormData = (formData, modelString, action, dslJson) => {
	// console.log("modelString: ", modelString);
	// console.log("formData: ", formData);
	const parsedData = transcriptToObjectsParsing(modelString, dslJson);

	const updateEntityAttributes = (entity, formData) => {
		Object.keys(formData).forEach((key) => {
			if (key !== "uniqueId" && key !== "type" && formData[key] !== "") {
				entity[key] = formData[key];
				if (key === "title") {
					entity.uniqueId = formData[key];
				}
			}
		});
	};

	// console.log("parsedData (before update): ", deepClone(parsedData));

	let entityFound = false;

	Object.keys(parsedData).forEach((type) => {
		const nodeTypeConfig = dslJson.nodeTypes[type];
		const entities = parsedData[type];

		if (nodeTypeConfig.draggable === false) {
			if (entities && entities.title === formData.uniqueId) {
				entityFound = true;
				if (action === "delete") {
					parsedData[type] = null;
					console.log(`Entity deleted for type: ${type}`);
				} else {
					updateEntityAttributes(entities, formData);
					console.log(`Entity updated for type: ${type}`, entities);
				}
			}
		} else if (Array.isArray(entities)) {
			const entityIndex = entities.findIndex((entity) => entity.title === formData.uniqueId);

			if (action === "delete") {
				if (entityIndex !== -1) {
					entities.splice(entityIndex, 1);
					console.log(`Entity deleted from array for type: ${type}`);
					console.log("entities: ", entities);
				}
			} else {
				if (entityIndex !== -1) {
					entityFound = true;
					updateEntityAttributes(entities[entityIndex], formData);
					console.log(`Entity updated in array for type: ${type}`, entities[entityIndex]);
				}
			}

			if (entities.length > 0) {
				parsedData[type] = entities;
			} else {
				delete parsedData[type]; // Remove the type key if entities is an empty array
			}
		}
	});


	// console.log("parsedData (before updates): ", deepClone(parsedData));

	if (!entityFound && action !== "delete") {
		console.log("notfound ");
		const newEntityType = formData.type;
		let newEntity = { ...formData };
		if (dslJson.nodeTypes[newEntityType].draggable === false) {
			parsedData[newEntityType] = newEntity;
		} else {
			if (!parsedData[newEntityType]) {
				parsedData[newEntityType] = [];
			}
			parsedData[newEntityType].push(newEntity);
		}
	}

	// console.log("parsedData (after update): ", deepClone(parsedData));

	let updatedModel = "";
	Object.keys(parsedData).forEach((type) => {
		const nodeTypeConfig = dslJson.nodeTypes[type];
		let entities = parsedData[type];

		const sectionComment = `// ${nodeTypeConfig.title} ------->`;

		if (Array.isArray(entities) && entities.length > 0) {
			// Filter out entities that have a parent, as they are nested nodes
			entities = entities.filter(entity => !entity.parent);

			entities.forEach((entity) => {
				updatedModel += sectionComment + "\n";
				if (nodeTypeConfig.nestedNodeTypes && nodeTypeConfig.nestedNodeTypes.length > 0) {

					// Attach nested nodes to their parent entity based on the parent property
					entity = replaceNestedNodes(entity, parsedData, dslJson);
				}
				updatedModel += generateConfig(type, entity, dslJson) + "\n";
			});
		} else if (entities) {
			updatedModel += sectionComment + "\n";
			updatedModel += generateConfig(type, entities, dslJson) + "\n";
		}
	});



	console.log("updatedModel (after update) end: ", updatedModel);
	return updatedModel.trim();
};

export { IconComponent, updateModelWithFormData };
