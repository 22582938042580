import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Grid, Typography, Avatar, TextField } from "@mui/material";
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';

import MDEditor from "@uiw/react-md-editor";

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from "@mui/icons-material/Visibility";
import AccountTreeIcon from '@mui/icons-material/AccountTree';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import Popup from "../components/Popup.js";
import Search from "../components/Search.js";
import Spinner from "../components/Spinner.js";
import { isFuzzyMatch, useSnackbar } from "../utils/index.js";

import { makeStyles } from '@mui/styles';

import { deleteProject, getProjects, newProject } from "../api/index.js";

const useStyles = makeStyles({
	buttonCursor: {
		cursor: "pointer",
	},
	select: {
		color: "#F1A542", // Set the desired color for the select
		"&:before": {
			borderColor: "#F1A542", // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: "#F1A542", // Set the desired color for the select's border when focused
		},
	},
});

const Projects = () => {
	const { success, error } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [searchFilter, setSearchFilter] = useState("");
	const [projects, setProjects] = useState([]);
	const [filtered, setFiltered] = useState(projects);
	const [addProjectPopupOpen, setAddProjectPopupOpen] = useState(false);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");

	const classes = useStyles();

	const navigate = useNavigate();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			const prjs = await getProjects();
			console.log(prjs);
			setProjects(prjs);
			setIsLoading(false);
		},
		[],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	useEffect(() => {
		setFiltered(projects.filter((us) => isFuzzyMatch(us.name, searchFilter)));
	}, [searchFilter, projects]);

	const addProjectSubmitHandler = async () => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await newProject(
				title,
				description,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddProjectPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const deleteProjectSubmitHandler = async (projectId) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await deleteProject(
				projectId,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	return (
		<>
			<Spinner open={isLoading} />
			<Popup
				width="800px"
				open={addProjectPopupOpen}
				title="Edit project information"
				onClose={() => {
					setAddProjectPopupOpen(false);
				}}
			>
				{/* Add text input to submit name */}
				<TextField
					label="title"
					variant="filled"
					sx={{
						width: "100%",
						mb: 2,
					}}
					InputProps={{
						style: { color: 'white' }, // Style for the input text
					}}
					value={title}
					onChange={(e) => {
						setTitle(e.target.value);
					}}
				/>
				<Typography
					sx={{
						color: "#bbb",
						fontSize: "0.9rem",
						mb: 1,
						pl: 0.5,
					}}
				>
					{"Description in MarkDown format:"}
				</Typography>
				<MDEditor
					hideToolbar
					value={description}
					height={500}
					maxHeight={1200}
					fullscreen={false}
					tabSize={2}
					style={{
						zIndex: 100,
						borderRadius: 10,
					}}
					onChange={setDescription}
				/>
				{/* Add button to submit broker */}
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						mt: 2,
					}}
				>
					<Chip
						key={-1}
						label="Submit"
						color="secondary"
						className={[classes.clickableChip].join(" ")}
						sx={{
							mr: 0.5,
						}}
						onClick={addProjectSubmitHandler}
					/>
				</Box>
			</Popup>
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			>

				<Grid
					container
					// item
					width="100%"
					flexDirection="row"
					justifyContent="center"
				>
					<Grid
						container
						item
						width="100%"
						xs={9}
						justifyContent="center"
					>
						<Grid
							container
							item
							width="100%"
							pr={2}
							mb={2}
							display="flex"
							minHeight="60px"
							alignItems="center"
							justifyContent="space-between"
							sx={{
								borderBottom: 0.1,
								borderColor: "secondary.main",
							}}
						>
							<Grid
								container
								item
								width="100%"
								pl={2}
								xs={7}
								display="flex"
								minHeight="50px"
								maxHeight="50px"
								alignItems="center"
							>
								<Grid
									item
									xs={9}
								>
									<Typography variant="h6" color="#ffffff">
										{"Your projects"}
									</Typography>
								</Grid>
								<Grid
									item
									xs={3}
									display="flex"
									flexDirection="row"
									alignItems="center"
									justifyContent="flex-end"
									pr={1}
								>
									<Chip
										key={-1}
										label="+"
										color="success"
										sx={{
											mr: 0.5,
										}}
										onClick={() => setAddProjectPopupOpen(true)}
									/>
								</Grid>
							</Grid>
							<Grid
								item
								xs={4}
							>
								<Search value={searchFilter} onChange={(event) => setSearchFilter(event.target.value)} />
							</Grid>
						</Grid>
						<Timeline
							sx={{
								[`& .${timelineItemClasses.root}:before`]: {
									flex: 0,
									padding: 0,
								},
							}}
							xs={7}
						>
							{
								filtered.length === 0 && (
									<Typography color="gray" pl={2} pb={1} pt={1} fontSize="0.8rem">
										{"No projects found."}
									</Typography>
								)
							}
							{filtered.map((e, ind) => (
								<TimelineItem key={ind}>
									<TimelineSeparator>
										<TimelineDot
											color="white"
											sx={{
												boxShadow: "2px 2px 14px #262835",
											}}
										>
											<AccountTreeIcon style={{ color: "green" }} />
										</TimelineDot>
										{ind !== filtered.length - 1 && <TimelineConnector />}
									</TimelineSeparator>
									<TimelineContent>
										<Grid
											container
											display="flex"
											flexDirection="row"
										>
											<Grid
												item
												xs={10}
												display="flex"
												flexDirection="column"
											>
												<Typography fontSize="1.1rem" color="#ffffff">
													{e.title}
												</Typography>
												<Typography color="#ffffff" fontSize="0.6rem" mb={0.5}>
													{`Created at ${e.createdAt.replace("T", " ").replace("Z", "")}`}
												</Typography>
											</Grid>
											<Grid
												item
												xs={2}
												display="flex"
												flexDirection="row"
												alignItems="center"
												justifyContent="flex-end"
											>
												<Badge
													color="secondary"
													badgeContent={e.collaborators.length}
													overlap="circular"
													sx={{
														mx: 1,
													}}
												>
													<PeopleAltIcon style={{ color: "white" }} />
												</Badge>
												<Badge
													color="secondary"
													badgeContent={e.models.length}
													overlap="circular"
													sx={{
														mx: 1,
														mr: 2,
													}}
												>
													<TextSnippetIcon style={{ color: "white" }} />
												</Badge>
												<Tooltip title="View project">
													<Avatar
														sx={{
															bgcolor: "#04598c",
															mr: 1,
															width: 38,
															height: 38,
														}}
														className={classes.buttonCursor}
														onClick={() => {
															navigate(`/projects/${e._id}`);
														}}
													>
														<VisibilityIcon color="white" />
													</Avatar>
												</Tooltip>
												<Avatar
													sx={{
														bgcolor: "red",
														width: 38,
														height: 38,
													}}
													className={classes.buttonCursor}
													onClick={() => {
														deleteProjectSubmitHandler(e._id);
													}}
												>
													<DeleteIcon />
												</Avatar>
											</Grid>
										</Grid>
									</TimelineContent>
								</TimelineItem>
							))}
						</Timeline>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default memo(Projects);
