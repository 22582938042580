import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles"; // Assuming you want to use MUI styling

// Utilize makeStyles for custom styles based on your theme
const useStyles = makeStyles((theme) => ({
	autocompleteInput__input: {
		backgroundColor: "#a9c3d2",
		color: "#000",
		borderRadius: "4px",
		"&:hover": {
			backgroundColor: "#fff",
			opacity: 0.8,
		},
	},
}));

const AutocompleteComponent = ({
  id = 'custom-autocomplete',
  label = 'Label',
  options = [], // This should be an array of objects for complex structures or strings for simple ones
  value,
  onChange,
  multiple = false,
  disabled = false,
}) => {
  const classes = useStyles();

  // Adjust getOptionLabel and value to support both single and multiple selections
  // For simple use case where options are just strings, this function returns the string itself
  const getOptionLabel = (option) => option;
  // Ensure the value is always in the expected format for the Autocomplete, whether it's multiple or not
  const actualValue = multiple ? value || [] : value;


  return (
    <Autocomplete
      multiple={multiple}
      id={id}
      options={options}
      getOptionLabel={getOptionLabel}
      value={actualValue}
      onChange={onChange}
      disabled={disabled}
      filterSelectedOptions={multiple} // Only apply filterSelectedOptions if multiple is true
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={multiple ? 'Select multiple options' : 'Select an option'}
          variant="filled"
          className={classes.autocompleteInput__input} // Adjusted className usage
        />
      )}
      // Applying sx prop here to override MUI styles directly if needed
      sx={{ width: '100%', '.MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' } }}
    />
  );
};

export default AutocompleteComponent;
