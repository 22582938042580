import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";
import ReactFlow, { Controls, Background, ReactFlowProvider, addEdge, useNodesState, useEdgesState } from "reactflow";
import "reactflow/dist/style.css";

import { InitializeNodesEdges, createNodeWithDefaultData } from "./initial-elements/initial-elements";
import Sidebar from "./sidebar_right/Sidebar";
import "reactflow/dist/base.css";
import "./index.css";
import CustomNode from "./custom_nodes/Nodes";
import { getEdgeTypes } from "./custom_edge/EdgeTypes";

const nodeTypes = {
	customNode: CustomNode,
};

const nodeTypesName = "customNode";

const defaultEdgeOptions = {
	type: "customEdge",
	markerEnd: "edge-circle",
};

const DnDnSnRFlow = ({ formSubmit, onFormSubmit, model, onOpenPopup, onScreenChange, modelid, dslJson }) => {
	const flowKey = `sm-auto-graphical-flow-position-${modelid}`;

	const reactFlowWrapper = useRef(null);
	const [reactFlowInstance, setReactFlowInstance] = useState(null);
	const [flowVersion, setFlowVersion] = useState(0);
	const [initializedHyperData, setInitializedHyperData] = useState([]);
	const [nodes, setNodes, onNodesChange] = useNodesState([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);
	const edgeTypes = useMemo(() => getEdgeTypes(dslJson), [dslJson]);

	useEffect(() => {
		const initializeFlow = () => {
			const {
				nodes: initialNodes,
				edges: initialEdges,
				hyperData: hyperData,
			} = InitializeNodesEdges(nodeTypesName, model, onOpenPopup, dslJson);
			setInitializedHyperData(hyperData);
			setNodes(restoreNodePositions(initialNodes));
			setEdges(initialEdges);
		};

		initializeFlow();
	}, [model, onOpenPopup, dslJson]);

	const restoreNodePositions = (initialNodes) => {
		const savedPositions = JSON.parse(localStorage.getItem(flowKey));
	
		if (savedPositions) {
			const updatedNodes = initialNodes.map((node) => {
				const savedPosition = savedPositions.find((pos) => pos.id === node.id);
				if (savedPosition) {
					// Only include style if width and height are present
					const updatedNode = {
						...node,
						position: savedPosition.position,
					};
	
					if (savedPosition.width && savedPosition.height) {
						updatedNode.style = {
							...node.style,
							width: savedPosition.width,
							height: savedPosition.height,
						};
					}
	
					return updatedNode;
				}
				return node;
			});
	
			return updatedNodes;
		}
	
		return initialNodes;
	};

	useEffect(() => {
		if (formSubmit) {
			setNodes(restoreNodePositions(nodes));
			setEdges(edges);
			onFormSubmit(false);
		}
	}, [formSubmit, nodes, edges, setNodes, setEdges, onFormSubmit]);

	const onConnect = useCallback(
		(params) => {
			setEdges((eds) => addEdge(params, eds));
			console.log("onConnect: ", params);
		},
		[setEdges],
	);

	const saveFlowStateToLocalStorage = useCallback(() => {
		if (reactFlowInstance) {
			const nodePositions = nodes.map((node) => {
				const nodePosition = {
					id: node.id,
					position: node.position,
				};
	
				if (node.style?.width && node.style?.height) {
					nodePosition.width = node.style.width;
					nodePosition.height = node.style.height;
				}
	
				return nodePosition;
			});
	
			try {
				localStorage.setItem(flowKey, JSON.stringify(nodePositions));
			} catch (error) {
				console.error("Error saving flow to local storage:", error);
			}
		}
	}, [nodes, edges, reactFlowInstance]);	

	useEffect(() => {
		saveFlowStateToLocalStorage();
	}, [nodes, edges, reactFlowInstance, saveFlowStateToLocalStorage]);

	const onNodeClick = useCallback(
		(event, node) => {
			const updatedEdges = edges.map((edge) => ({
				...edge,
				style: {
					...edge.style,
					strokeOpacity: edge.source === node.id || edge.target === node.id ? 1 : 0.2,
					labelOpacity: edge.source === node.id || edge.target === node.id ? 1 : 0.2,
				},
			}));
			setEdges(updatedEdges);
		},
		[edges, setEdges],
	);

	const onPaneClick = useCallback(() => {
		const resetEdges = edges.map((edge) => ({
			...edge,
			style: {
				...edge.style,
				strokeOpacity: 1,
				labelOpacity: 1,
			},
		}));
		setEdges(resetEdges);
	}, [edges, setEdges]);

	const onDragOver = useCallback((event) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = "move";
	}, []);

	const onDrop = useCallback(
		(event) => {
			event.preventDefault();

			const reactFlowBounds = reactFlowWrapper.current?.getBoundingClientRect();

			const type = event.dataTransfer.getData("application/reactflow");

			if (typeof type === "undefined" || !type) {
				return;
			}

			if (reactFlowInstance && reactFlowBounds) {
				const position = reactFlowInstance.screenToFlowPosition({
					x: event.clientX - reactFlowBounds.left,
					y: event.clientY,
				});

				const newNode = createNodeWithDefaultData(
					nodeTypesName,
					type.toLowerCase(),
					position,
					model,
					onOpenPopup,
					dslJson,
				);
				console.log("newNode: ", newNode);

				setNodes((nds) => nds.concat(newNode));

				onOpenPopup(newNode.data, "create");

				setFlowVersion(flowVersion + 1);
			}
		},
		[reactFlowInstance, setNodes, flowVersion, nodeTypesName, model, onOpenPopup, dslJson],
	);

	const renderGradients = () => {
		const gradients = [];

		Object.keys(dslJson.nodeTypes).forEach((type) => {
			const nodeType = dslJson.nodeTypes[type];
			if (nodeType.colorConfig) {
				Object.keys(nodeType.colorConfig).forEach((key) => {
					const colorConfig = nodeType.colorConfig[key];
					gradients.push(
						<linearGradient id={`edge-gradient-${type}-${key}`} key={`edge-gradient-${type}-${key}`}>
							<stop offset="0%" stopColor={colorConfig.startColor} />
							<stop offset="100%" stopColor={colorConfig.endColor} />
						</linearGradient>,
					);
				});
			}
		});

		return gradients;
	};

	return (
		<div className="dndflow">
			<div className="reactflow-wrapper" ref={reactFlowWrapper}>
				<ReactFlow
					key={flowVersion}
					nodes={nodes}
					edges={edges}
					nodeTypes={nodeTypes}
					edgeTypes={edgeTypes}
					defaultEdgeOptions={defaultEdgeOptions}
					onNodesChange={onNodesChange}
					onEdgesChange={onEdgesChange}
					onNodeClick={onNodeClick}
					onPaneClick={onPaneClick}
					onConnect={onConnect}
					onInit={setReactFlowInstance}
					onDrop={onDrop}
					onDragOver={onDragOver}
					fitView
					minZoom={0.2}
				>
					<Controls showInteractive={false} />
					<svg>
						<defs>
							<linearGradient id="edge-gradient">
								<stop offset="0%" stopColor="#ae53ba" />
								<stop offset="100%" stopColor="#2a8af6" />
							</linearGradient>
							{renderGradients()}
							<marker
								id="edge-circle"
								viewBox="-5 -5 10 10"
								refX="0"
								refY="0"
								markerUnits="strokeWidth"
								markerWidth="10"
								markerHeight="10"
								orient="auto"
							>
								<circle stroke="#2a8af6" strokeOpacity="0.75" r="2" cx="0" cy="0" />
							</marker>
						</defs>
					</svg>
					<Background color="#ccc" gap={12} size={1} />
				</ReactFlow>
			</div>
			<Sidebar
				openPopup={onOpenPopup}
				hyperData={initializedHyperData}
				onScreenChange={onScreenChange}
				dslJson={dslJson}
			/>
		</div>
	);
};

export default function CreateGraph(props) {
	return (
		<ReactFlowProvider>
			<DnDnSnRFlow {...props} />
		</ReactFlowProvider>
	);
}
