// eslint-disable-next-line no-unused-vars
import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";

import {
	Avatar,
	Grid,
	IconButton,
	Typography,
	Chip,
	TextField,
	Box,
} from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from "@mui/icons-material/Visibility";
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';

import { makeStyles } from '@mui/styles';

import MDEditor from "@uiw/react-md-editor";

import { memo, useCallback, useEffect, useState } from "react";

import Popup from "../components/Popup.js";
import Spinner from "../components/Spinner.js";
import Form from "../components/Form.js";
import { useSnackbar, jwt } from "../utils/index.js";

import {
	getProject,
	getUsersModels,
	addCollaboratorToProject,
	removeCollaboratorFromProject,
	addModelToProject,
	removeModelFromProject,
	getCollaborators,
	updateProjectMetadata,
	cloneModel,
	newUserModel,
} from '../api/index.js';

import { getDslsImages, getDslsNames, getDslsConstants } from '../dsls/index.js';

const useStyles = makeStyles({
	buttonCursor: {
		cursor: "pointer",
	},
	select: {
		color: "#F1A542", // Set the desired color for the select
		"&:before": {
			borderColor: "#F1A542", // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: "#F1A542", // Set the desired color for the select's border when focused
		},
	},
	selectSmall: {
		color: "orange", // Set the desired color for the select
		"&:before": {
			borderColor: "orange", // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: "orange", // Set the desired color for the select's border when focused
		},
		fontSize: "0.8rem",
	},
	dotSquare: {
		position: 'relative',
		width: 200, // Adjust as needed
		height: 40, // Adjust as needed
		borderRadius: 0,
		borderColor: null,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: 'none',
	},
	dot: {
		position: 'absolute',
		width: 16, // Adjust as needed
		height: 16, // Adjust as needed
		backgroundColor: 'white', // Dot color
		borderRadius: '50%',
	},
});

const ProjectScreen = (params) => {
	const { error, success } = useSnackbar();
	const { projectid } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [project, setProject] = useState(null);
	const [collaborators, setCollaborators] = useState([]);
	const [description, setDescription] = useState("");
	const [tmpDescription, setTmpDescription] = useState("");
	const [tmpTitle, setTmpTitle] = useState("");
	const [models, setModels] = useState([]);
	const [modelsDsls, setModelsDsls] = useState([]);
	const [usersModels, setUsersModels] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [user, setUser] = useState(jwt.decode());
	const [dslsImages, setDslsImages] = useState({});
	const [dslsNames, setDslsNames] = useState({});
	const [dslsConstants, setDslsConstants] = useState({});
	const [userCollaborators, setUserCollaborators] = useState([]);
	const [editMetadataPopupOpen, setEditMetadataPopupOpen] = useState(false);
	const [cloneModelPopupOpen, setCloneModelPopupOpen] = useState(false);
	const [createModelPopupOpen, setCreateModelPopupOpen] = useState(false);
	const [modelToClone, setModelToClone] = useState(null);

	const [selectedDsl, setSelectedDsl] = useState("");
	const [selectedDslForNewModel, setSelectedDslForNewModel] = useState("");

	const classes = useStyles();
	const navigate = useNavigate();

	// eslint-disable-next-line no-unused-vars
	const [screenSize, setScreenSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const fetchData = useCallback(
		async () => {
			console.log(params);
			setIsLoading(true);

			// Fetch the project
			const { success: scs, project: prj } = await getProject(projectid);
			console.log("Project", prj);
			if (scs) {
				// Find names of owners for each model
				for (let i = 0; i < prj.models.length; i++) {
					const creator = prj.models[i].creator;
					for (const collab of prj.collaborators) {
						if (collab._id === creator) {
							prj.models[i].creator_fullname = collab.fullname;
							break;
						}
					}
				}

				setProject(prj);
				setDescription(prj.description);
				setTmpDescription(prj.description);
				setTmpTitle(prj.title);
				setCollaborators(prj.collaborators);
				setModels(prj.models);

				// Fetch the user's models
				const { success: scs2, models: usModels } = await getUsersModels(user.id);
				// console.log(usModels);
				if (scs2) {
					// Remove the models that are already in the project
					const insertableModels = [];
					for (const mdl of usModels) {
						let found = false;
						for (const mdl2 of prj.models) {
							if (mdl._id === mdl2._id) {
								found = true;
								break;
							}
						}

						if (!found) {
							insertableModels.push(mdl);
						}
					}

					// Find dsls from models
					const dsls = new Set(insertableModels.map((mdl) => mdl.model_type));
					setModelsDsls([...dsls].sort());

					setUsersModels(insertableModels);
				} else {
					error();
				}
			} else {
				error();
			}

			const _collaborators = await getCollaborators(user.id);
			// remove the project collaborators from the user collaborators
			_collaborators.collaborators = _collaborators.collaborators.filter(
				(colab) => !prj.collaborators.some((colab2) => colab2.email === colab.email),
			);
			console.log("Collaborators", _collaborators.collaborators);
			setUserCollaborators(_collaborators.collaborators);

			// Get dsls images
			const _dslsConstants = await getDslsConstants();
			setDslsConstants(_dslsConstants);
			console.log("DSLs names", _dslsConstants);
			const _dslsImages = await getDslsImages();
			setDslsImages(_dslsImages);
			const _dslsNames = await getDslsNames();
			setDslsNames(_dslsNames);

			setIsLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[error, projectid, user],
	);

	const removeCollaboratorHandler = async (userid) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await removeCollaboratorFromProject(
				projectid,
				userid,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const addCollaboratorHandler = async (values) => {
		setIsLoading(true);
		console.log("Add colab:", values);
		try {
			const { success: successCode, message } = await addCollaboratorToProject(
				projectid,
				values.target.value,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const addModelHandle = async (event) => {
		setIsLoading(true);
		console.log(event.target.value);
		try {
			const { success: successCode, message } = await addModelToProject(
				projectid,
				event.target.value,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const removeModelHandle = async (_modelId) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await removeModelFromProject(
				projectid,
				_modelId,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const editMetadataHandle = async () => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await updateProjectMetadata(
				projectid,
				tmpTitle,
				tmpDescription,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setEditMetadataPopupOpen(false);
		setIsLoading(false);
	};

	const cloneModelSubmitHandler = async (values) => {
		setIsLoading(true);
		console.log(values);
		try {
			const { success: successCode, message } = await cloneModel(
				modelToClone._id,
				values.title,
				values.description,
			);

			if (successCode) {
				success("Model was cloned in your account");
			} else {
				error(message);
			}

			setModelToClone(null);
			setCloneModelPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const createModelSubmitHandler = async (values) => {
		setIsLoading(true);
		console.log(values);
		try {
			const { success: successCode, message } = await newUserModel(
				user.id,
				selectedDslForNewModel,
				values.title,
				values.description,
				projectid,
			);

			if (successCode) {
				success("Model was cloned in your account");
			} else {
				error(message);
			}

			setCreateModelPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const cloneModelFormContent = [
		{
			customType: "wideInput",
			id: "title",
			type: "input",
			multiline: false,
			width: 400,
			placeholder: "New title",
			value: modelToClone?.title,
		},
		{
			customType: "wideInput",
			id: "description",
			type: "input",
			multiline: true,
			minRows: 3,
			width: 400,
			placeholder: "New description",
			value: modelToClone?.description,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Clone model",
		},
	];

	const createModelFormContent = [
		{
			customType: "wideInput",
			id: "title",
			type: "input",
			multiline: false,
			width: 400,
			placeholder: "Title",
			value: modelToClone?.title,
		},
		{
			customType: "wideInput",
			id: "description",
			type: "input",
			multiline: true,
			minRows: 3,
			width: 400,
			placeholder: "Description",
			value: modelToClone?.description,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Create model",
		},
	];

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	return (
		<>
			<Spinner open={isLoading} />
			<Popup
				width="800px"
				open={editMetadataPopupOpen}
				title="Edit project information"
				onClose={() => {
					setEditMetadataPopupOpen(false);
				}}
			>
				{/* Add text input to submit name */}
				<TextField
					label="title"
					variant="filled"
					sx={{
						width: "100%",
						mb: 2,
					}}
					InputProps={{
						style: { color: 'white' }, // Style for the input text
					}}
					value={tmpTitle}
					onChange={(e) => {
						setTmpTitle(e.target.value);
					}}
				/>
				<MDEditor
					hideToolbar
					value={tmpDescription}
					height={500}
					maxHeight={1200}
					fullscreen={false}
					tabSize={2}
					style={{
						zIndex: 100,
						borderRadius: 10,
					}}
					onChange={setTmpDescription}
				/>
				{/* Add button to submit broker */}
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						mt: 2,
					}}
				>
					<Chip
						key={-1}
						label="Submit"
						color="secondary"
						className={[classes.clickableChip].join(" ")}
						sx={{
							mr: 0.5,
						}}
						onClick={editMetadataHandle}
					/>
				</Box>
			</Popup>
			<Popup
				width="800px"
				open={cloneModelPopupOpen}
				title="Clone the model into a new one"
				onClose={() => {
					setCloneModelPopupOpen(false);
				}}
			>
				<Form
					content={cloneModelFormContent}
					onSubmit={cloneModelSubmitHandler}
				/>
			</Popup>
			<Popup
				width="800px"
				open={createModelPopupOpen}
				title="Create a new model"
				onClose={() => {
					setCreateModelPopupOpen(false);
				}}
			>
				<Grid
					item
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="flex-start"
					mb={1}
				>
					<Typography color="#fff" fontSize="1rem" mr={2}>
						{"Select DSL"}
					</Typography>
					<FormControl variant="standard" sx={{ minWidth: 120, mr: 2 }}>
						<Select
							className={classes.selectSmall}
							value={selectedDslForNewModel || ""}
							label="Model"
							size="small"
							onChange={(e) => setSelectedDslForNewModel(e.target.value)}
						>
							{
								// dslsConstants is an object
								Object.keys(dslsConstants)
									.filter((mdl) => dslsConstants[mdl].enabled)
									.map((mdl, mdlInd) => [
										<MenuItem key={`subheader-${mdlInd}`} value={dslsConstants[mdl].short}>
											{dslsConstants[mdl].name}
										</MenuItem>,
									])
							}
						</Select>
					</FormControl>

				</Grid>
				<Form
					content={createModelFormContent}
					onSubmit={createModelSubmitHandler}
				/>
			</Popup>
			<Grid
				container
				item
				mt={2}
				mb={2}
				width="100%"
				display="flex"
				flexDirection="row"
				justifyContent="space-evenly"
			>
				<Grid
					container
					item
					width="100%"
					xs={3.9}
					display="flex"
					flexDirection="column"
				>
					<Grid
						item
						display="flex"
						flexDirection="column"
						alignItems="center"
					>
						<Grid
							item
							sx={{
								borderRadius: 10,
								alignItems: "center",
								flex: 1,
								width: "100%",
							}}
						>
							<Grid
								container
								item
								width="100%"
								pl={2}
								mb={1}
								display="flex"
								minHeight="50px"
								maxHeight="50px"
								alignItems="center"
							>
								<Grid
									item
									container
									xs={12}
									flexDirection="row"
									alignItems="center"
								>
									{/* Edit button */}
									<Avatar
										sx={{
											bgcolor: "#262835",
											mr: 2,
											width: 38,
											height: 38,
										}}
										className={classes.buttonCursor}
									>
										<IconButton
											color="secondary"
											onClick={() => setEditMetadataPopupOpen(true)}
										>
											<EditIcon />
										</IconButton>
									</Avatar>
									<Typography variant="h5" color="#ffffff">
										{project?.title}
									</Typography>
								</Grid>
							</Grid>
							<MDEditor
								hideToolbar
								value={description}
								height={screenSize.height - 200}
								maxHeight={1200}
								fullscreen={false}
								tabSize={2}
								preview="preview"
								style={{
									zIndex: 100,
									borderRadius: 10,
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					item
					width="100%"
					xs={7.9}
					display="flex"
					flexDirection="column"
					sx={{
						borderTop: 0,
						borderRadius: "20px",
					}}
				>
					<Grid
						container
						item
						width="100%"
						pl={2}
						mb={1}
						display="flex"
						minHeight="50px"
						maxHeight="50px"
						alignItems="center"
					>
						<Grid
							item
							xs={6}
						>
							<Typography variant="h6" color="#ffffff">
								{"Project collaborators"}
							</Typography>
						</Grid>
						<Grid
							item
							xs={6}
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="flex-end"
							pr={1}
						>
							<Typography color="#fff" fontSize="1rem" mr={2}>
								{"Add collaborator"}
							</Typography>
							<FormControl variant="standard" sx={{ minWidth: 120, mr: 2 }}>
								<Select
									className={classes.selectSmall}
									value=""
									label="Collaborator"
									size="small"
									onChange={addCollaboratorHandler}
								>
									{
										userCollaborators.map((mdl, mdlInd) => (
											<MenuItem key={mdlInd} value={mdl.email}>{`${mdl.fullname}`}</MenuItem>
										))
									}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<Grid
						container
						item
						width="100%"
						pl={2}
						mb={1}
						display="flex"
						minHeight="50px"
						maxHeight="50px"
						borderRadius="20px"
						alignItems="center"
					>
						{
							collaborators.length === 0 && (
								<Typography color="gray" pl={2} pb={1} pt={1} fontSize="0.8rem">
									{"No collaborators found."}
								</Typography>
							)
						}
						{
							collaborators.map((e, ind) => (
								<Grid
									key={ind}
									item
									display="flex"
									flexDirection="row"
									alignItems="center"
									justifyContent="flex-start"
								>
									<Chip
										key={ind}
										label={e.fullname}
										color="secondary"
										sx={{
											mr: 0.5,
										}}
										onDelete={() => removeCollaboratorHandler(e._id)}
									/>
								</Grid>
							))
						}
					</Grid>
					<Grid
						container
						item
						width="100%"
						pl={2}
						mt={2}
						display="flex"
						minHeight="50px"
						maxHeight="50px"
						alignItems="center"
					>
						<Grid
							item
							xs={4}
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="flex-start"
						>
							<Typography variant="h6" color="#ffffff">
								{"Project's models"}
							</Typography>
							<Grid
								item
								display="flex"
								flexDirection="row"
								alignItems="center"
								justifyContent="flex-end"
								pl={1}
							>
								<Chip
									key={-1}
									label="Create new"
									color="success"
									sx={{
										mr: 0.5,
									}}
									onClick={() => setCreateModelPopupOpen(true)}
								/>
							</Grid>
						</Grid>
						<Grid
							item
							xs={8}
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="flex-end"
							pr={1}
						>
							<Grid
								item
								display="flex"
								flexDirection="row"
								alignItems="center"
								justifyContent="flex-start"
							>
								<Typography color="#fff" fontSize="1rem" mr={2}>
									{"Select DSL"}
								</Typography>
								<FormControl variant="standard" sx={{ minWidth: 120, mr: 2 }}>
									<Select
										className={classes.selectSmall}
										value={selectedDsl || ""}
										label="Model"
										size="small"
										onChange={(e) => setSelectedDsl(e.target.value)}
									>
										{
											modelsDsls.map((mdl, mdlInd) => [
												<MenuItem key={`subheader-${mdlInd}`} value={mdl}>{dslsNames[mdl]}</MenuItem>,
											])
										}
									</Select>
								</FormControl>

							</Grid>
							<Grid
								item
								display="flex"
								flexDirection="row"
								alignItems="center"
								justifyContent="flex-start"
							>
								<Typography color="#fff" fontSize="1rem" mr={2}>
									{"Add model"}
								</Typography>
								<FormControl variant="standard" sx={{ minWidth: 80, mr: 2 }}>
									<Select
										className={classes.selectSmall}
										value=""
										label="Model"
										size="small"
										onChange={addModelHandle}
									>
										{
											usersModels.filter((mdl) => mdl.model_type === selectedDsl).map((mdl, mdlInd) => [
												<MenuItem key={`item-${mdlInd}-${mdlInd}`} value={mdl._id}>{`${mdl.title}`}</MenuItem>,
											])
										}
									</Select>
								</FormControl>

							</Grid>
						</Grid>
					</Grid>
					<Timeline
						sx={{
							[`& .${timelineItemClasses.root}:before`]: {
								flex: 0,
								padding: 0,
							},
						}}
					>
						{
							models.length === 0 && (
								<Typography color="gray" pl={2} pb={1} pt={1} fontSize="0.8rem">
									{"No models found."}
								</Typography>
							)
						}
						{models.map((e, ind) => (
							<TimelineItem key={ind}>
								<TimelineSeparator>
									{
										// Show dsl image
										dslsImages[e.model_type] && (
											<TimelineDot
												sx={{
													backgroundColor: "#193256",
												}}
												classes={{
													root: classes.dotSquare,
												}}
											>
												<img
													src={dslsImages[e.model_type]}
													alt={dslsNames[e.model_type]}
													style={{
														height: 35,
													}}
												/>
											</TimelineDot>
										)
									}
								</TimelineSeparator>
								<TimelineContent>
									<Grid
										container
										display="flex"
										flexDirection="row"
									>
										<Grid
											item
											xs={10}
											display="flex"
											flexDirection="column"
										>
											<Typography fontSize="1rem" color="#ffffff">
												{e.title}
											</Typography>
											{e.description && (
												<Typography color="#c8e6c9" fontSize="0.8rem" mb={0.5}>
													{
														e.description
													}
												</Typography>
											)}

											<Typography color="#c8e6c9" fontSize="0.8rem" mb={0.5}>
												{
													`Owner: ${e.creator_fullname}`
												}
											</Typography>
											<Typography color="#ffffff" fontSize="0.6rem" mb={0.5}>
												{`Last updated on ${e.updatedAt.replace("T", " ").replace("Z", "")}`}
											</Typography>
										</Grid>
										<Grid
											item
											xs={2}
											display="flex"
											flexDirection="row"
											alignItems="center"
											justifyContent="flex-end"
										>
											<Tooltip title="View model">
												<Avatar
													sx={{
														bgcolor: "#04598c",
														mr: 1,
														width: 38,
														height: 38,
													}}
													className={classes.buttonCursor}
													onClick={() => { navigate(`/dsls/${e.model_type}/${e._id}`); }}
												>
													<VisibilityIcon color="white" />
												</Avatar>
											</Tooltip>
											<Tooltip title="Clone model">
												<Avatar
													sx={{
														bgcolor: "#04598c",
														mr: 1,
														width: 38,
														height: 38,
													}}
													className={classes.buttonCursor}
													onClick={() => {
														setModelToClone(e);
														setCloneModelPopupOpen(true);
													}}
												>
													<ContentCopyIcon color="white" />
												</Avatar>
											</Tooltip>
											<Tooltip title="Remove from project">
												<Avatar
													sx={{
														bgcolor: "red",
														width: 38,
														height: 38,
													}}
													className={classes.buttonCursor}
													onClick={() => {
														removeModelHandle(e._id);
													}}
												>
													<PlaylistRemoveIcon />
												</Avatar>
											</Tooltip>
										</Grid>
									</Grid>
								</TimelineContent>
							</TimelineItem>
						))}
					</Timeline>
				</Grid>
			</Grid>
		</>
	);
};

export default memo(ProjectScreen);
