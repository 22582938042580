import ky from "ky";
import queryString from "query-string";

import { jwt } from "../utils/index.js";

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const rootApi = ky.extend({
	timeout: false,
	prefixUrl: `${REACT_APP_MAIN_SERVER_URL}/api`,
	retry: {
		statusCodes: [401, 408, 413, 429, 502, 503, 504],
		limit: 2,
		methods: ["get", "post", "put", "head", "delete", "options", "trace"],
	},
	hooks: {
		beforeRequest: [({ headers }) => {
			headers.set("x-access-token", jwt.getToken());
		}],
		beforeRetry: [
			async ({ request: { method }, error }) => {
				if (error?.response?.status === 401) {
					const res = await rootApi.extend({ throwHttpErrors: false, retry: 0 }).get("api/refresh");
					if (res.status === 401) {
						jwt.destroyToken();
						window.location.href = "/";
					} else {
						const { token } = await res.json();
						jwt.setToken(token);
					}
				} else if (method === "POST") {
					throw error;
				}
			},
		],
		afterResponse: [
			(_req, _opts, res) => {
				const { status } = res;
				if (status === 500) {
					return new Response(JSON.stringify({ success: false }), { status: 200 });
				}

				if (status === 404) {
					window.location.href = "/";
				}

				if (status === 401) {
					window.location.href = "/unauthorized";
				}

				return res;
			},
		],
	},
});

const api = {
	get: (path, searchParams) => rootApi.get(path, { searchParams: queryString.stringify(searchParams) }).json(),
	post: (path, json) => rootApi.post(path, { json }).json(),
	put: (path, json) => rootApi.put(path, { json }).json(),
	patch: (path, json) => rootApi.patch(path, { json }).json(),
	delete: (path, json) => rootApi.delete(path, { json }).json(),
};

export default api;

// Basic user auth calls
export const authenticate = (username, password) => api.post("authenticate", { username, password });
export const authenticateGoogle = (token) => api.post("authenticateGoogle", { token });
export const forgotPassword = (username) => api.post("forgotPassword", { username });
export const resetPassword = (password, token) => api.post("resetPassword", { password, token });
export const signUp = (username, fullname, email, password) => api.post("createUser", { username, fullname, email, password });
export const invitedSignUp = (username, fullname, email, password, token) => api.post("createUserInvited", { username, fullname, email, password, token });

// Files calls
export const uploadFile = (body) => rootApi.post("file/", { body }).json();
export const reUploadFile = (body) => rootApi.put("file/", { body }).json();
export const deleteFile = (info) => api.post("file/delete/", info);

// User calls
export const inviteUser = (email) => api.post("user", { email });
export const removeUser = (id) => api.post("user/delete", { id });
export const getUsersData = () => api.get("user");
export const saveMarkdownTheme = (id, theme) => api.put(`user/${id}/saveMarkdownTheme`, { theme });
export const getMarkdownTheme = (id) => api.get(`user/${id}/markdownTheme`);
export const getUsersModels = (id, dsl, useProjects = false) => api.get(`user/${id}/models`, { dsl, useProjects });
export const newUserModel = (id, dsl, title, description, projectId = null) => api.post(`user/newModel`, { id, dsl, title, description, projectId });
export const getUserSecrets = (id) => api.get(`user/${id}/secrets`);

// User groups and collaborators calls
export const addCollaborator = (id, email) => api.post(`user/${id}/collaborator`, { email });
export const removeCollaborator = (id, collaboratorId) => api.post(`user/${id}/collaborator/delete`, { collaboratorId });
export const getCollaborators = (id) => api.get(`user/${id}/collaborators`);
export const getGroups = (id) => api.get(`user/${id}/groups`);
export const getGroup = (id) => api.get(`user/group/${id}`);
export const removeGroup = (id) => api.post("user/group/delete", { id });
export const addGroup = (name, description) => api.post("user/group", { name, description });
export const addUserToGroup = (groupId, userId) => api.post(`user/group/${groupId}/addUser`, { userId });
export const removeUserFromGroup = (groupId, userId) => api.post(`user/group/${groupId}/removeUser`, { userId });
export const getGroupUsers = (groupId) => api.get(`user/group/${groupId}/users`);

// Models calls
export const getDslsStats = () => api.get("models/stats");
export const removeModel = (id) => api.post("models/delete", { id });
export const getModel = (id) => api.get(`models/${id}`);
export const validateModel = (id, value) => api.post(`models/${id}/validate`, { value });
export const saveModel = (id, value) => api.post(`models/${id}/save`, { value });
export const saveModelSubtype = (id, subtype) => api.post(`models/${id}/saveSubtype`, { subtype });
export const importToModel = (id, importedModelId) => api.post(`models/${id}/import`, { importedModelId });
export const transformModel = (id, newDsl, title, description) => api.post(`models/${id}/transform`, { newDsl, title, description });
export const generateCode = (id, validationId, generationType, generationOutput) => api.post(`models/${id}/generate`, { validationId, generationType, generationOutput });
export const deployModel = (id, validationId) => api.post(`models/${id}/deploy`, { validationId });
export const promptModel = (id, prompt, useModel) => api.post(`models/${id}/prompt`, { prompt, useModel });
export const clearModelPrompts = (id) => api.post(`models/${id}/clearPrompts`);
export const cloneModel = (id, title, description) => api.post(`models/${id}/clone`, { title, description });
export const editModelMetadata = (id, title, description) => api.post(`models/${id}/edit`, { title, description });

// Projects calls
export const getProjects = () => api.get("projects");
export const getProject = (id) => api.get(`projects/${id}`);
export const removeProject = (id) => api.post("projects/delete", { id });
export const newProject = (title, description) => api.post("projects", { title, description });
export const addModelToProject = (projectId, modelId) => api.post(`projects/${projectId}/addModel`, { modelId });
export const removeModelFromProject = (projectId, modelId) => api.post(`projects/${projectId}/removeModel`, { modelId });
export const addCollaboratorToProject = (projectId, email) => api.post(`projects/${projectId}/addCollaborator`, { email });
export const removeCollaboratorFromProject = (projectId, userId) => api.post(`projects/${projectId}/removeCollaborator`, { userId });
export const updateProjectMetadata = (projectId, title, description) => api.post(`projects/${projectId}/updateMetadata`, { title, description });
export const deleteProject = (projectId) => api.post(`projects/${projectId}/delete`);

// Secrets groups calls
export const newSecretsGroup = (title, description) => api.post("secrets", { title, description });
export const editSecretsGroup = (id, title, description) => api.post("secrets/edit", { id, title, description });
export const getSecretsGroups = () => api.get("secrets");
export const deleteSecretsGroup = (id) => api.post("secrets/delete", { id });

// Secrets calls
export const getSecrets = (groupId) => api.get(`secrets/${groupId}`);
export const newSecret = (groupId, name, description, value) => api.post(`secrets/${groupId}/newSecret`, { name, description, value });
export const editSecret = (id, name, description, value) => api.post(`secrets/editSecret`, { id, name, description, value });
export const deleteSecret = (id) => api.post(`secrets/deleteSecret`, { id });
